import React, { useState } from 'react';
import axios from 'axios';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row'; 
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../pages/card/card.css'

const ImageUploadComponent = (props) => {
    const [files, setFiles] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFiles(prevFiles => ({ ...prevFiles, [name]: files[0] }));
    };

    const handleUpload = async () => {
        const formData = new FormData();
        setUploading(true);
        for (let key in files) {
            formData.append(key, files[key]);
        }

        try {
            const response = await axios.post(`https://api.alientrader.cards/card/upload/${props.cardId}`, formData, {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });
            console.log(response.data);
            props.onUrlsReceived(response.data); 
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    return (
        <Row className='justify-content-center'>
            <Row className='justify-content-center'>
                <button onClick={handleUpload} className='uploadButton'>Upload</button>
            </Row>
            <Row className='uploadProgressRow'>
                {uploading && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
            </Row>
            <Row className='justify-content-center'>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Grader Front</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row className='justify-content-center'>
                                <input type="file" name="grader_front_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Grader Back</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="grader_back_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Scan Front</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="scan_front_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Scan Back</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="scan_back_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>        <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Alien Trader Front</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="alientrader_front_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Alien Trader Back</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="alientrader_back_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Miscellaneous</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="misc_image_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Miscellaneous</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="misc_image2_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='custom-card-box shadow-sm'>
                    <Row>
                        <div className='custom-info-header'>Miscellaneous</div>
                    </Row>
                    <Row className='card-images'>
                        <Col>
                            <Row>
                                <input type="file" name="misc_image3_url" onChange={handleFileChange} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='uploadProgressRow'>
                {uploading && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
            </Row>
            <Row className='justify-content-center'>
                <button onClick={handleUpload} className='uploadButton'>Upload</button>
            </Row>
        </Row>
    );
};

export default ImageUploadComponent;
