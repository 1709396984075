import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import '../card.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Axios from 'axios';
import CoverDisplay from '../../components/ImagesPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Cards() {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("")
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    //Card Info
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await Axios.get(`https://api.alientrader.cards/data/dbsearch`, {
            params: { q: query },
          });
          setData(res.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      if (query.length === 0 || query.length > 2) fetchData();
    }, [query]);

    return (
      <div className=''>
        <div className='actions'>
          <div className='subBack'><ArrowBackIcon onClick={goBack}/></div>
          <div className='cardWallGrader'><h1>Search</h1></div>
          <div className='subEdit'></div>
        </div>
        <div className='search-db'>
          <input
            className="cardSearch"
            placeholder="Search..."
            value={query}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        </div>
        <br />
        <Container>
          <Row>
            {data.map((item, index) => (
            <Col sm={2} className='custom-card-box shadow-sm' key={item.card_id}>
              <Row>
                <div className='custom-info-header'><Link to={`/card/${item.card_id}`}>{item.card_pokemon}</Link></div>
              </Row>
              <Row className='card-info'>
                <Col className="col-right-light">{item.card_id}</Col>
              </Row>
              <Row className='card-images'>
                <CoverDisplay key={index} image={item}/>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card_pokemon}</Col>
                <Col className="col-right">{item.card_set_year}</Col>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card}</Col>
                <Col className="col-right">{item.card_language}</Col>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card_owner}</Col>
                <Col className="col-right">{item.card_set}</Col>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card_grade_number} {item.card_grade}</Col>
                <Col className="col-center">{item.card_grader}</Col>
                <Col className="col-right">{item.card_grade_cert}</Col>
              </Row>
              <br/>
            </Col>
            ))}
            {/* {data.map((item, index) => (
              <Col className='imagesPageRow d-flex align-items-center justify-content-center' key={item.card_id}>
                  <CoverDisplay key={index} image={item}/>
              </Col>
            ))} */}
          </Row>
        </Container>
        <br />
      </div>
    );
};

export default Cards;
