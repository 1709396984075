import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import logoPSA from '../../../assets/logos/PSA-Black.png'
import logoCGC from '../../../assets/logos/CGC-Black.png'
import logoAGS from '../../../assets/logos/AGS-Black.png'
import logoNFL from '../../../assets/logos/NFL-Black.png'
import logoPokemon from '../../../assets/logos/Pokemon-Black.png'
//import logoAlien from '../../../assets/Alien-Black.png'

function cardWalls() {
  return (
    <Col sm={10} md={2} lg={2} xl={1} className='DashCardWallNewestPadding'>
    <Col className='info-box shadow-sm'>
      <Row><div className='info-header'>Card Walls</div></Row>
      <Row className='d-flex align-items-center justify-content-center'>
        <Col>
            <Link to='/dashboard/card-wall/graded/psa'><img
                src={logoPSA}
                alt="PSA Logo"
                height="40"
            /></Link>
        </Col>
        <Col>
            <Link to='/dashboard/card-wall/graded/cgc'><img
                src={logoCGC}
                alt="CGC Logo"
                height="40"
            /></Link>
        </Col>
        <Col>
            <Link to='/dashboard/card-wall/graded/ags'><img
                src={logoAGS}
                alt="AGS Logo"
                height="36"
            /></Link>
        </Col>
    </Row>
    <Row className='d-flex align-items-center justify-content-center'>
        <Col>
            <Link to='/dashboard/card-wall/game/football'><img
                src={logoNFL}
                alt="NFL Logo"
                height="40"
            /></Link>
        </Col>
        <Col>
            <Link to='/dashboard/card-wall/game/pokemon'><img
                src={logoPokemon}
                alt="Pokemon Logo"
                height="40"
            /></Link>
        </Col>
      </Row>
    </Col>
    </Col>
  )
}

export default cardWalls