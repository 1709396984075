import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './styles.css'

function SoldInfo() {
  const [profit, setProfit] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/profit')
      .then((response) => {
        setProfit(response.data[0].TotalProfit);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [cost, setCost] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/cost')
      .then((response) => {
        setCost(response.data[0].TotalCost);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [soldPrice, setSoldPrice] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/price')
      .then((response) => {
        setSoldPrice(response.data[0].TotalSoldPrice);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [soldCount, setSoldCount] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/count')
      .then((response) => {
        setSoldCount(response.data[0].TotalSoldCount);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [soldFeeGrade, setSoldFeeGrade] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/card_fee_grade')
      .then((response) => {
        setSoldFeeGrade(response.data[0].SoldFeeGrade);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [soldFeeShip, setSoldFeeShip] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/card_solds_ship')
      .then((response) => {
        setSoldFeeShip(response.data[0].SoldFeeShip);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [soldFee, setSoldFee] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/card_sold_fees')
      .then((response) => {
        setSoldFee(response.data[0].SoldFee);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);
  const [soldFeeAds, setSoldFeeAds] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/sold/card_sold_fees_ads')
      .then((response) => {
        setSoldFeeAds(response.data[0].SoldFeeAds);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);

  const salesMinusFees = soldPrice - soldFee - soldFeeShip - soldFeeAds

  return (
    <Col xs={5} sm={5} md={4} lg={3} xl={3} className='info-box shadow-sm'>
      <Row><div className='info-header'>Sold Info</div></Row>
      <Row>
        <Col xs={6}><div className='info-title'>Cards Sold:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{soldCount.toLocaleString()}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}><div className='info-title'>Profit:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{profit.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}><div className='info-title'>Total Sales:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{soldPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}><div className='info-title'>Total Cost:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{cost.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}><div className='info-title'>Grading Cost:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{soldFeeGrade.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}><div className='info-title'>Shipping Fees:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{soldFeeShip.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col><div className='info-title'>Selling Fees:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{soldFee.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col><div className='info-title'>Selling Ad Fees:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{soldFeeAds.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row>
        <Col><div className='info-title'>Sales - Fees:</div></Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='info-value'>{salesMinusFees.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
    </Col>
  )
}

export default SoldInfo