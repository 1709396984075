import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Axios from 'axios';
import './cardsDashboard.css'

function Cards() {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("")

    const columns: GridColDef[] = [
      { 
        field: 'card_id', 
        headerName: 'ID', 
        width: 50, 
        align: 'center'
      },
      { 
        field: 'card_game', 
        headerName: 'Game', 
        width: 80,
        headerAlign: 'center',
        align: 'center'
      },
      {
        field: 'cover_image',
        headerName: 'Card',
        width: 90,
        align: 'center',
        renderCell: (params) => {
          let parsed = JSON.parse(params.value); // Parse the value into JSON
          let imageUrl;

          if (parsed[0]?.thumbnail === "none") {
            imageUrl = "http://alientrader.net/cards/cards/images/cards/Temp.jpg";
          } else {
            if (parsed[0]?.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
              imageUrl = parsed[0]?.thumbnail.replace("/home/u825454141/domains/alientrader.net/public_html/", "http://alientrader.net/");
            } else {
              imageUrl = "https://api.alientrader.cards/" + parsed[0]?.thumbnail;
            }
          }          
          let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
          return (
            <div>
              <a href={`/card/${cardId}`}>
                <img src={imageUrl} alt="card" style={{height: '100px', borderRadius: '5px'}}/>
              </a>
            </div>
          );
        }
      },
      {
        field: 'card_pokemon', 
        headerName: 'Pokemon',
        width: 80,
        renderCell: (params) => {
          let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
          return (
            <a href={`/card/${cardId}`}>
              {params.value}
            </a>
          );
        }
      },
      { 
        field: 'card', 
        headerName: 'Variety / Pedigree:', 
        width: 120,
      },
      { 
        field: 'card_set_year', 
        headerName: 'Year:', 
        width: 50, 
      },
      { 
        field: 'card_set', 
        headerName: 'Set:', 
        width: 120, 
      },
      { 
        field: 'card_language', 
        headerName: 'Language:', 
        width: 70, 
      },
      { 
        field: 'card_grader', 
        headerName: 'Grader:', 
        width: 58,
        align: 'center'
      },
      { 
        field: 'card_grade_number', 
        headerName: 'Grade #:', 
        width: 65,
        align: 'center'
      },
      { 
        field: 'card_grade', 
        headerName: 'Grade:', 
        width: 120, 
      },
      { 
        field: 'card_grade_cert', 
        headerName: 'Certifcation #:', 
        width: 90,
        align: 'center'
      },
      {
        field: 'card_grade_link', 
        headerName: 'Link:',
        width: 50,
        renderCell: (params) => {
          return (
            <a href={`${params.value}`}>
              [LINK]
            </a>
          );
        }
      },
      {
        field: 'card_grade_sub',
        headerName: 'Submission:',
        width: 60,
      },
      { 
        field: 'card_price', 
        headerName: 'Price:', 
        width: 65,
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "$0.00";
          }
          return `$${price.toFixed(2)}`;
        }
      },
      { 
        field: 'card_fee_grade', 
        headerName: 'Grade Fee:', 
        width: 75,
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "$0.00";
          }
          return `$${price.toFixed(2)}`;
        }
      },
      { 
        field: 'card_fee_shiptax', 
        headerName: 'Ship & Tax:', 
        width: 75,
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "$0.00";
          }
          return `$${price.toFixed(2)}`;
        }
      },
      { 
        field: 'card_total_cost', 
        headerName: 'Total Cost:', 
        width: 70,
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "$0.00";
          }
          return `$${price.toFixed(2)}`;
        }
      },
      { 
        field: 'card_date_received', 
        headerName: 'Received Date:', 
        width: 80,
        align: 'right',
        renderCell: (params) => {
          let date = new Date(params.value);
          return date.toLocaleDateString();  // format: "mm/dd/yyyy" 
        }
      },
      { 
        field: 'card_owner', 
        headerName: 'Owner:', 
        width: 85,
      },
      { 
        field: 'card_sold_price', 
        headerName: 'Sold Price', 
        width: 70,
        align: 'right',
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "-";
          }
          return `$${price.toFixed(2)}`;
        },
      },
      { 
        field: 'card_sold_fees', 
        headerName: 'Fees', 
        width: 70,
        align: 'right',
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "-";
          }
          return `$${price.toFixed(2)}`;
        },      },
      { 
        field: 'card_solds_ship', 
        headerName: 'Ship Fee', 
        width: 70,
        align: 'right',
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "-";
          }
          return `$${price.toFixed(2)}`;
        },
      },
      { 
        field: 'card_profit', 
        headerName: 'Profit:', 
        width: 70,
        align: 'right',
        renderCell: (params) => {
          let price = params.value;
          if (!price) {
            return "-";
          }
          return `$${price.toFixed(2)}`;
        },
      },
      {
        field: 'card_sold_at',
        headerName: 'Date At:',
        width: 60,
      },
      {
        field: 'card_sold_date',
        headerName: 'Date Sold:',
        width: 100,
        align: 'right',
        renderCell: (params) => {
          if (!params.value) {
            return "-";
          }
          let date = new Date(params.value);
          return date.toLocaleDateString();  // format: "mm/dd/yyyy" 
        }
      },
    ];
    //Card Info
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await Axios.get(`https://api.alientrader.cards/data/dbsearch`, {
            params: { q: query },
          });
          setData(res.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      if (query.length === 0 || query.length > 2) fetchData();
    }, [query]);

    return (
      <div className=''>
        <div className='search-db'>
          <input
            className="search"
            placeholder="Search..."
            value={query}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        </div>
        <br />
        <div style={{ height: 680, width: '100%'}}>
          <DataGrid 
            rows={data} 
            columns={columns} 
            getRowId={(row) => row.card_id}
            rowHeight={110}
            sx={{
              fontSize: 12,
            }}
          />
        </div>
      </div>
    );
};

export default Cards;
