import './dashboard.css'
import { Outlet } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../assets/Alien-Blank.png";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import InsightsIcon from '@mui/icons-material/Insights';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
//import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';


function DashNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <MenuOpenIcon onClick={handleShow} className='dashNavIcon'/>
      <Offcanvas show={show} onHide={handleClose} backdrop={false} scroll className='DashOffcanvas'>
        <Offcanvas.Header closeButton closeVariant='white'>
          <Offcanvas.Title><img
              alt=""
              src={Logo}
              width="45"
              height="45"
              className="d-inline-block align-center"
         />   Dashboard</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavDropdown className='dashNavLink' title="Dashboard" id="basic-nav-dropdown">
            <NavDropdown.Item className='dashNavLink' href="/dashboard/cards">Search</NavDropdown.Item>
            <NavDropdown.Item className='dashNavLink' href="/dashboard/sold">Sold Reports</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className='dashNavLink' href="/dashboard/card-wall"><WallpaperIcon /> Card Wall</Nav.Link>
          <Nav.Link className='dashNavLink' href="/"><DeveloperBoardIcon /> Dashboard Home</Nav.Link>
          <Nav.Link className='dashNavLink' href="/search"><SearchRoundedIcon /> Card Database</Nav.Link>
          <Nav.Link className='dashNavLink' href="/cards/new"><YoutubeSearchedForIcon /> Newest Cards</Nav.Link>
          <Nav.Link className='dashNavLink' href="/dashboard/financials"><InsightsIcon /> Financials</Nav.Link>
          <Nav.Link className='dashNavLink' href="/dashboard/sold"><LocalAtmIcon /> Sold Reports</Nav.Link>
          <Nav.Link className='dashNavLink' href="/dashboard/submissions"><CardMembershipIcon /> Submissions</Nav.Link>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


function dashboard() {
  return (
    <div>
      <div className='dashboard'>
        <div className='dashNav'>
          <div className='dashNavLinks'>
          <DashNav key='DashNav' placement='start' name='DashNav' />
          </div>
          <div className='dashNavLinks'>
            <div className='dashNavLink'><Nav.Link href="/dashboard/card-wall"><WallpaperIcon /></Nav.Link></div>
            <div className='dashNavLink'><Nav.Link href="/dashboard/"><DeveloperBoardIcon /></Nav.Link></div>
            <div className='dashNavLink'><Nav.Link href="/dashboard/search"><SearchRoundedIcon /></Nav.Link></div>
            <div className='dashNavLink'><Nav.Link href="/dashboard/cards/new"><YoutubeSearchedForIcon /></Nav.Link></div>
            <div className='dashNavLink'><Nav.Link href="/dashboard/financials"><InsightsIcon /></Nav.Link></div>
            <div className='dashNavLink'><Nav.Link href="/dashboard/sold"><LocalAtmIcon /></Nav.Link></div>
            {/* <div className='dashNavLink'><Nav.Link href="/dashboard/sold"><PointOfSaleIcon /></Nav.Link></div> */}
            <div className='dashNavLink'><Nav.Link href="/dashboard/submissions"><CardMembershipIcon /></Nav.Link></div>
          </div>
        </div>
        <div className='dashContent'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default dashboard