import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Year() {
    const {id} = useParams()
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [YearCardPriceData, setYearCardPriceData] = useState([]);
    const YearCardPrice = YearCardPriceData ? YearCardPriceData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/card_price`)
        .then((response) => {
            setYearCardPriceData(response.data[0].YearCardPrice);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);

    const [YearCardFeeShipTaxData, setYearCardFeeShipTaxData] = useState([]);
    const YearCardFeeShipTax = YearCardFeeShipTaxData ? YearCardFeeShipTaxData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/card_fee_shiptax`)
        .then((response) => {
            setYearCardFeeShipTaxData(response.data[0].YearCardFeeShipTax);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);

    const [YearCardSoldPriceData, setYearCardSoldPriceData] = useState([]);
    const YearCardSoldPrice = YearCardSoldPriceData ? YearCardSoldPriceData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/card_sold_price`)
        .then((response) => {
            setYearCardSoldPriceData(response.data[0].YearCardSoldPrice);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);

    const [YearSubTotalCostData, setYearSubTotalCostData] = useState([]);
    const YearSubTotalCost = YearSubTotalCostData ? YearSubTotalCostData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/submission/sub_total_cost`)
        .then((response) => {
            setYearSubTotalCostData(response.data[0].YearSubTotalCost);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);

    const [YearCardSoldFeesData, setYearCardSoldFeesData] = useState([]);
    const YearCardSoldFees = YearCardSoldFeesData ? YearCardSoldFeesData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/card_sold_fees`)
        .then((response) => {
            setYearCardSoldFeesData(response.data[0].YearCardSoldFees);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);

    const [YearCardSoldFeesAdsData, setYearCardSoldFeesAdsData] = useState([]);
    const YearCardSoldFeesAds = YearCardSoldFeesAdsData ? YearCardSoldFeesAdsData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/card_sold_fees_ads`)
        .then((response) => {
            setYearCardSoldFeesAdsData(response.data[0].YearCardSoldFeesAds);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);
    const [YearCardSoldFeesShipData, setYearCardSoldFeesShipData] = useState([]);
    const YearCardSoldFeesShip = YearCardSoldFeesShipData ? YearCardSoldFeesShipData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
    useEffect(() => {
      axios.get(`https://api.alientrader.cards/reports/${id}/card_solds_ship`)
        .then((response) => {
            setYearCardSoldFeesShipData(response.data[0].YearCardSoldFeesShip);
        })
        .catch((error) => {
          console.error(`Error fetching data: ${error}`);
        });
    }, [id]);

    const TotalSalesExpensesData = YearCardSoldFeesData + YearCardSoldFeesAdsData + YearCardSoldFeesShipData
    const TotalSalesExpenses = TotalSalesExpensesData ? TotalSalesExpensesData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";

  return (
    <div>
        <div className='actions'>
            <div className='subBack'><ArrowBackIcon onClick={goBack}/></div>
            <div className='sub'><h1>{id} P and L</h1></div>
            <div className='subEdit'></div>
        </div>
        <Container fluid='xxl'>
            <Row className='d-flex justify-content-center'>
                <Col xs={9} sm={5} md={5} lg={5} xl={4}>
                    <Row>
                        <Col sm={10} md={10} lg={10} xl={10} className='info-box shadow-sm'>
                            <Row><div className='info-header'>Expenses</div></Row>
                            <Row>
                                <Col><div className='info-title'>Cards:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearCardPrice.toLocaleString()}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><div className='info-title'>Shipping & Tax:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearCardFeeShipTax.toLocaleString()}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><div className='info-title'>Grading:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearSubTotalCost.toLocaleString()}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={9} sm={5} md={5} lg={5} xl={4}>
                    <Row>
                        <Col sm={10} md={10} lg={10} xl={10} className='info-box shadow-sm'>
                            <Row><div className='info-header'>Income</div></Row>
                            <Row>
                                <Col><div className='info-title'>Sales:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearCardSoldPrice.toLocaleString()}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={10} md={10} lg={10} xl={10} className='info-box shadow-sm'>
                            <Row><div className='info-header'>Sales Expenses</div></Row>
                            <Row>
                                <Col><div className='info-title'>Fees:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearCardSoldFees.toLocaleString()}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><div className='info-title'>Ads:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearCardSoldFeesAds.toLocaleString()}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><div className='info-title'>Shiping:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{YearCardSoldFeesShip.toLocaleString()}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><div className='info-title'>Total:</div></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <div className='info-value'>{TotalSalesExpenses.toLocaleString()}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Year