import React, { useState } from 'react';
import './CoverDisplay.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSwipeable } from 'react-swipeable';

const CoverDisplay = ({ frontImage, backImage }) => {
  const [showBackImage, setShowBackImage] = useState(false);

  const toggleImage = () => setShowBackImage(prevState => !prevState);

  const handlers = useSwipeable({
    onSwipedLeft: toggleImage,
    onSwipedRight: toggleImage,
    onTap: toggleImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <Container className='cover-display-container'>
      <Row>
        <Col className='imageBorder'>
          <div className='image-wrapper' {...handlers}>
            <div className={`flip-card-inner ${showBackImage ? 'flipped' : ''}`}>
              <div className='flip-card-front'>
                <img className='cover-image-display shadow' src={frontImage.name} alt={frontImage.usrName} />
              </div>
              <div className='flip-card-back'>
                <img className='cover-image-display shadow' src={backImage.name} alt={backImage.usrName} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CoverDisplay;
