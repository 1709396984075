import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { utcToZonedTime, format } from 'date-fns-tz';
import Axios from 'axios';
import './card.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const getWisconsinDate = () => {
  const timeZone = 'America/Los_Angeles';
  const now = new Date();
  const zonedDate = utcToZonedTime(now, timeZone);
  return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });
};

const gameOptions = ['Select Game', 'Pokemon','Yu-Gi-Oh','Football', 'Baseball', 'Basketball', 'F1','Magic','Marvel','DC', 'Weiss Schwarz', 'Other'];
const languageOptions = ['Select Language', 'English', 'Japanese', 'Chinese', 'Korean', 'Thai', 'Spanish', 'German', 'Italian'];

function AddCard() {
  const [key, setKey] = useState('info');
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [cardGame, setCardGame] = useState('');
  const [cardPokemon, setCardPokemon] = useState('');
  const [cardVariety, setCardVariety] = useState('');
  const [cardLanguage, setCardLanguage] = useState('');
  const [cardSetYear, setCardSetYear] = useState('');
  const [cardSet, setCardSet] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardOwner, setCardOwner] = useState('');
  const [cardPrice, setCardPrice] = useState("0");
  const [cardFeeGrade, setCardFeeGrade] = useState("0");
  const [cardFeeShipTax, setCardFeeShipTax] = useState("0");
  const [cardPurchasedAt, setCardPurchasedAt] = useState('');
  const [cardDateReceived, setCardDateReceived] = useState('');
  const [cardNotes, setCardNotes] = useState('');
  const [cardLink, setCardLink] = useState('');
  const cardGradeNumber = ""  
  
  const handleSave = async () => {
    try {
      const cardData = {
        card_game: cardGame,
        card_pokemon: cardPokemon,
        card: cardVariety,
        card_language: cardLanguage,
        card_set_year: cardSetYear,
        card_set: cardSet,
        card_number: cardNumber,
        card_owner: cardOwner,
        card_price: cardPrice,
        card_fee_grade: cardFeeGrade,
        card_fee_shiptax: cardFeeShipTax,
        card_date_received: cardDateReceived,
        card_purchased_at: cardPurchasedAt,
        card_notes: cardNotes,
        card_link: cardLink,
        card_grade_number: cardGradeNumber,
        updated_date: getWisconsinDate(),
      };

      const response = await Axios.post('https://api.alientrader.cards/card/add', cardData);

      if (response.data.success) {
        alert('Card saved successfully!');
        navigate(`/card/${response.data.card_id}`);
      } else {
        alert('Error saving card.');
      }
    } catch (error) {
      console.error("There was an error saving the card", error);
      alert('Error saving card.');
    }
  };

  return (
    <div className='card'>
        <div className='actions'>
            <div className='subBack'><CancelIcon fontSize="large" onClick={goBack}/></div>
            <div className='sub'><h1>Add New Card</h1></div>
            <div className='subEdit'><button className='edit-button' onClick={handleSave}><SaveIcon fontSize="large" /></button></div>
        </div>
      <Tabs id="card-information" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
      <Tab eventKey="info" title="Main Info">
      <Container>
      <Row>
          <Col sm lg={4} className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Card Info</div></Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'>Game:</Col>
              <Col>
                <div className='custom-input-val'>
                  <select
                      value={cardGame}
                      id="card_game"
                      className='edit-input'
                      onChange={(e) => setCardGame(e.target.value)}
                  >
                      {gameOptions.map((game, index) => (
                          <option key={index} value={game}>
                              {game}
                          </option>
                      ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'>Pokemon / Character / Player:</Col>
              <Col>
                <div className='custom-input-val'>
                 <input
                   type="text"
                   id="card_pokemon"
                   className='edit-input'
                   value={cardPokemon}
                   onChange={(e) => setCardPokemon(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'>Variety / Pedigree:</Col>
              <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card"
                        className='edit-input'
                        onChange={(e) => setCardVariety(e.target.value)}
                    />
                    </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'>Language:</Col>
              <Col>
                <div className='custom-input-val'>
                  <select
                    value={cardLanguage}
                    id="card_language"
                    className='edit-input'
                    onChange={(e) => setCardLanguage(e.target.value)}
                  >
                    {languageOptions.map((language, index) => (
                      <option key={index} value={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'><div>Year:</div></Col>
              <Col>
              <div className='custom-input-val'>
              <input
                        type="text"
                        id="card_set_year"
                        className='edit-input'
                        onChange={(e) => setCardSetYear(e.target.value)}
                    />
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Set:</div></Col>
              <Col>
              <div className='custom-input-val'>
                <input
                        type="text"
                        id={`card_set`}
                        className='edit-input'
                        onChange={(e) => setCardSet(e.target.value)}
                    />
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Card Number:</div></Col>
                <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card_number"
                        className='edit-input'
                        onChange={(e) => setCardNumber(e.target.value)}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Owner:</div></Col>
                <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card_owner"
                        className='edit-input'
                        onChange={(e) => setCardOwner(e.target.value)}
                    />
                    </div>
                </Col>
            </Row>
            <br />
          </Col>
          <Col sm lg={4} className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Cost Info</div></Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Cost: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card_price"
                        value={cardPrice}
                        className='edit-input'
                        onChange={(e) => setCardPrice(e.target.value)}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Grading Fees: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card_fee_grade"
                        value={cardFeeGrade}
                        className='edit-input'
                        onChange={(e) => setCardFeeGrade(e.target.value)}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Ship & Tax: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card_fee_shiptax"
                        value={cardFeeShipTax}
                        className='edit-input'
                        onChange={(e) => setCardFeeShipTax(e.target.value)}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Total Cost: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                        <input
                            type="text"
                            id="card_total_cost"
                            className='edit-input'
                            disabled
                        />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Recieved Date:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                        <input
                            type="date"
                            id="card_date_received"
                            className='edit-input'
                            onChange={(e) => setCardDateReceived(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'>Purchased At:</Col>
              <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card"
                        className='edit-input'
                        onChange={(e) => setCardPurchasedAt(e.target.value)}
                    />
                    </div>
              </Col>
            </Row>
            <br />
          </Col>
        <Col sm className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Misc Info</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={3} className='custom-info-title'><div>Card Link:</div></Col>
                <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        id="card_link"
                        className='edit-input'
                        onChange={(e) => setCardLink(e.target.value)}
                    />
                    </div>
                </Col>
            </Row>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg className='custom-note-box shadow-sm'>
          <Row><div className='custom-info-header'>Notes</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
              <Col>
              <div className='custom-input-val'>
                    <textarea
                        type="textarea"
                        id="card_notes"
                        className='edit-input'
                        onChange={(e) => setCardNotes(e.target.value)}
                    />
                </div>
                <br />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      </Tab>
      </Tabs>
      <div className='actions'>
            <div className='subBack'><CancelIcon fontSize="large" onClick={goBack}/></div>
            <div className='sub'><h1>-</h1></div>
            <div className='subEdit'><button className='edit-button' onClick={handleSave}><SaveIcon fontSize="large" /></button></div>
        </div>
    </div>
  )
}

export default AddCard