import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './styles.css'

function TotalInfo() {
  const [CardTotalCost, setCardTotalCost] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/reports/card_total_cost')
      .then((response) => {
        setCardTotalCost(response.data[0].CardTotalCost);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);

  const [CardFeeGrade, setCardFeeGrade] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/reports/card_fee_grade')
      .then((response) => {
        setCardFeeGrade(response.data[0].CardFeeGrade);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);

  const [CardFeeShipTax, setCardFeeShipTax] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/reports/card_fee_shiptax')
      .then((response) => {
        setCardFeeShipTax(response.data[0].CardFeeShipTax);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);

  const [CardPrice, setCardPrice] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/reports/card_price')
      .then((response) => {
        setCardPrice(response.data[0].CardPrice);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);

  const [InventoryCount, setInventortCount] = useState([]);
  useEffect(() => {
    Axios.get('https://api.alientrader.cards/reports/inventory_count')
      .then((response) => {
        setInventortCount(response.data[0].InventoryCount);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, []);

  return (
    <Col xs={5} sm={5} md={4} lg={3} xl={3} className='info-box shadow-sm'>
      <Row><div className='info-header'>Current Inventory</div></Row>
      <Row>
        <Col xs={6}><div className='info-title'>Total Cards:</div></Col>
        <Col>
          <div className='info-value'>{InventoryCount.toLocaleString()}</div>
        </Col>
      </Row>
      <Row>
        <Col><div className='info-title'>Total Cost:</div></Col>
        <Col>
          <div className='info-value'>{CardTotalCost.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row className='d-flex align-items-center justify-content-center'>
        <Col><div className='info-title'>Cost:</div></Col>
        <Col>
          <div className='info-value'>{CardPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row className='d-flex align-items-center justify-content-center'>
        <Col><div className='info-title'>Grading Fees:</div></Col>
        <Col>
          <div className='info-value'>{CardFeeGrade.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row className='d-flex align-items-center justify-content-center'>
        <Col><div className='info-title'>Shipping & Tax Fees:</div></Col>
        <Col>
          <div className='info-value'>{CardFeeShipTax.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</div>
        </Col>
      </Row>
      <Row><br /><br /></Row>
    </Col>
  )
}

export default TotalInfo