import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './ImageDisplay.css'
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox} from 'lightbox.js-react'

const ImageDisplay = ({ image }) => {
  return (
    <Col>
        <Row>
          <SlideshowLightbox 
            className='container grid grid-cols-3 gap-2 mx-auto' 
            showThumbnails={false}
            showThumbnailIcon={false}
            showSlideshowIcon={false}
            showFullScreenIcon={false}
            showMagnificationIcons={true}
            imgAnimation="fade"
            modalClose="clickOutside">
            <img className='card-image-display shadow' src={image.name} alt={image.usrName}/>
          </SlideshowLightbox> 
        </Row>
    </Col>
  );
};

export default ImageDisplay;