import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Submission() {
  const [data, setData] = useState([]);
  const columns: GridColDef[] = [
    { 
      field: 'sub_id', 
      headerName: 'ID', 
      width: 50, 
      align: 'center',
      renderCell: (params) => {
        let subId = params.row.sub_id;
        return (
          <a href={`/dashboard/submissions/${subId}`}>
            {params.value}
          </a>
        )
      }
    },
    { 
      field: 'sub_text', 
      headerName: 'Sub Title', 
      width: 140, 
      renderCell: (params) => {
        let subId = params.row.sub_id;
        return (
          <a href={`/dashboard/submissions/${subId}`}>
            {params.value}
          </a>
        )
      }
    },
    { 
      field: 'sub_grader', 
      headerName: 'Grader', 
      width: 50, 
      align: 'center'
    },
    { 
      field: 'sub_order_number', 
      headerName: 'Order Number', 
      width: 100, 
      align: 'center',
      renderCell: (params) => {
        let subId = params.row.sub_id; // Replace 'id' with the correct property name for the card ID
        return (
          <a href={`/dashboard/submissions/${subId}`}>
            {params.value}
          </a>
        );
      }
    },
    { 
      field: 'sub_total_cards', 
      headerName: 'Total Cards', 
      width: 90, 
      align: 'center'
    },
    { 
      field: 'sub_total_cost', 
      headerName: 'Total Cost:', 
      width: 70,
      align: 'right',
      renderCell: (params) => {
        let price = params.value;
        if (!price) {
          return "$0.00";
        }
        return `$${price.toFixed(2)}`;
      }
    },
    { 
      field: 'sub_date', 
      headerName: 'Sub Date:', 
      width: 90,
      align: 'center',
      renderCell: (params) => {
        let date = new Date(params.value);
        return date.toLocaleDateString();  // format: "mm/dd/yyyy" 
      }
    },
    { 
      field: 'sub_return_date', 
      headerName: 'Return Date:', 
      width: 90,
      align: 'center',
      renderCell: (params) => {
        let date = new Date(params.value);
        return date.toLocaleDateString();  // format: "mm/dd/yyyy" 
      }
    },
  ]

  useEffect(() => {
    axios.get('https://api.alientrader.cards/submission')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); 


  return (
    <div className='sold'>
            <div><h1><Link to='/dashboard/submissions'>Submissions</Link></h1></div>
            <Container fluid='xxl'>
              <Row className='d-flex justify-content-center'>
                <Col>
                  <div style={{ height: 500, width: '100%'}}>
                    <DataGrid 
                      rows={data} 
                      columns={columns} 
                      getRowId={(row) => row.sub_id} 
                      rowHeight={30}
                      sx={{
                        fontSize: 12,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
  )
}

export default Submission;
