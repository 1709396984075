import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Initialise context
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check if user is logged in when the app loads
  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const { data } = await axios.get('https://api.alientrader.cards/user-status');
        setUser(data.user);
        setLoading(false);
      } catch (err) {
        setUser(null);
        setLoading(false);
      }
    };
    checkUserStatus();
  }, []);

  const login = async (username, password) => {
    try {
      const { data } = await axios.post('https://api.alientrader.cards/auth/login', { user: username, pwd: password });
      console.log(data.user);
      setUser(data.user);

      // Save the access token to localStorage
      localStorage.setItem('accessToken', data.accessToken);

      // Set the default headers for axios
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;

      return true;
    } catch (err) {
      return false;
    }
  };

  const logout = async () => {
    try {
      await axios.post('https://api.alientrader.cards/auth/logout');

      // Remove the access token from localStorage
      localStorage.removeItem('accessToken');

      // Reset the default headers for axios
      delete axios.defaults.headers.common['Authorization'];

      setUser(null);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
