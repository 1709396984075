import '../card.css'
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import Axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoverDisplay from '../../components/ImagesPage';

function GraderImages() {
  const [cardList, setCardList] = useState([]);
  const {id} = useParams()

  useEffect(()=> {
    Axios.get(`https://api.alientrader.cards/card-wall/owner/Sold/last-sold`)
    .then((response)=> {
      setCardList(response.data);
    })
    .catch(error => {
      console.error('Error fetching card data:', error);
    });
  }, [id])

  return (
    <div className="imagesPage">
      <Container fluid>
        <Row>
          {cardList.map((item, index) => (
            <Col className='imagesPageRow d-flex align-items-center justify-content-center' key={item.card_id}>
                <CoverDisplay key={index} image={item}/>
            </Col>
          ))}
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default GraderImages;