import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Navbar.css'
import EbayLogo from '../assets/ebay-96.png'

function footer() {
  return (
    <Navbar bg="white" variant='white'>
      <Container fluid='xl' className='footer'>
          <Navbar.Text>
            © 2023 Alien Trader Co LLC All rights reserved.
          </Navbar.Text>
          <Navbar.Text>
          <Nav.Link href="https://www.ebay.com/str/alientraderco"><img
                alt=""
                src={EbayLogo}
                width="48"
                height="48"
                className="d-inline-block align-center"
              /></Nav.Link>
          </Navbar.Text>
      </Container>
    </Navbar>
  );
}

export default footer;