import './financials.css'
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom'
//import Axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function Financials() {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
  return (
    <div className="imagesPage">
        <div className='actions'>
            <div className='subBack'><ArrowBackIcon onClick={goBack}/></div>
            <div className='cardWallGrader'><h1>Financials</h1></div>
            <div className='subEdit'></div>
        </div>
      <Container fluid>
        <Row>
          <Col>
            <Link to={'/dashboard/reports/2023'}>2023</Link>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  )
}

export default Financials