import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../context/AuthProvider'; // Import the AuthContext
import { Link, useNavigate } from 'react-router-dom';

import './login.css';
import BannerImage from "../../assets/Space-4.jpg";
import Logo from "../../assets/Alien-Blank.png";

function Login() {
  const { login } = useContext(AuthContext); // Get the login method from the context
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/dashboard/'); // Go back to the previous page
      }, 1000); // Redirect after 1 second
    }
  }, [success, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await login(user, pwd); // Use the login method from context
    if (result) {
      setSuccess(true);
    } else {
      setErrMsg('Invalid username or password.');
    }
  };

  return (
    <>
      {success ? (
        <div>
          <h1>You are logged in!</h1>
          <br />
          <div className='login-logo'>
            <Link to="/"><img src={Logo} alt="Alien Trader" /></Link>
            <div><h1 className='white'>Alien Trader Co</h1></div>
          </div>
        </div>
      ) : (
        <div className='login'>
          <div className='rightside' style={{ backgroundImage: `url(${BannerImage})` }}>
            <div className='login-logo'>
              <Link to="/"><img src={Logo} alt="Alien Trader" /></Link>
              <div><h1 className='white'>Alien Trader Co</h1></div>
            </div>
            <div className='login-box shadow-sm'>
              <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              <h2>Please Login</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder='Username'
                  id="username"
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />
                <input
                  type="password" // Change input type to 'password'
                  placeholder='Password'
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
                <span>Don't have an account? <Link to="/register">Register</Link></span>
                <button type="submit">Login</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
