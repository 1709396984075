import '../card.css'
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import Axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoverDisplay from '../../components/ImagesPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function GraderImages() {
  const [cardList, setCardList] = useState([]);
  const {id} = useParams()
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(()=> {
    Axios.get(`https://api.alientrader.cards/card-wall/set/${id}`)
    .then((response)=> {
      setCardList(response.data);
    })
    .catch(error => {
      console.error('Error fetching card data:', error);
    });
  }, [id])

  return (
    <div className="imagesPage">
        <div className='actions'>
            <div className='subBack'><ArrowBackIcon onClick={goBack}/></div>
            <div className='cardWallGrader'><h1>{id}</h1></div>
            <div className='subEdit'></div>
        </div>
      <Container fluid>
        <Row>
          {cardList.map((item, index) => (
            <Col className='imagesPageRow d-flex align-items-center justify-content-center' key={item.card_id}>
                <CoverDisplay key={index} image={item}/>
            </Col>
          ))}
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default GraderImages;