import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Axios from "axios";
import "./db.css";

function DB() {
  const [cardList, setCardList] = useState([]);
  const [query, setQuery] = useState("");

  const columns: GridColDef[] = [
    {
      field: 'card_id', 
      headerName: 'ID',
      width: 50,
      align: 'center',
      renderCell: (params) => {
        let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
        return (
          <a href={`/card/${cardId}`}>
            {params.value}
          </a>
        );
      }
    },
    {
      field: 'cover_image',
      headerName: 'Card',
      width: 90,
      align: 'center',
      renderCell: (params) => {
        let parsed = JSON.parse(params.value); // Parse the value into JSON
        let imageUrl;

        if (parsed[0]?.thumbnail === "none") {
          imageUrl = "http://alientrader.net/cards/cards/images/cards/Temp.jpg";
        } else {
          if (parsed[0]?.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
            imageUrl = parsed[0]?.thumbnail.replace("/home/u825454141/domains/alientrader.net/public_html/", "http://alientrader.net/");
          } else {
            imageUrl = "https://api.alientrader.cards/" + parsed[0]?.thumbnail;
          }
        }          
        let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
        return (
          <div>
            <a href={`/card/${cardId}`}>
              <img src={imageUrl} alt="card" style={{height: '100px', borderRadius: '5px'}}/>
            </a>
          </div>
        );
      }
    },
    {
      field: 'card_pokemon', 
      headerName: 'Pokemon',
      width: 120,
      renderCell: (params) => {
        let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
        return (
          <a href={`/card/${cardId}`}>
            {params.value}
          </a>
        );
      }
    },
    { 
      field: 'card', 
      headerName: 'Variety', 
      width: 100,
    },
    { 
      field: 'card_set_year', 
      headerName: 'Year:', 
      width: 50, 
    },
    { 
      field: 'card_set', 
      headerName: 'Set:', 
      width: 120, 
    },
    { 
      field: 'card_language', 
      headerName: 'Language:', 
      width: 70, 
    },
    { 
      field: 'card_grader', 
      headerName: 'Grader:', 
      width: 58,
      align: 'center'
    },
    { 
      field: 'card_grade_number', 
      headerName: 'Grade #:', 
      width: 65,
      align: 'center'
    },
    { 
      field: 'card_grade_cert', 
      headerName: 'Certifcation #:', 
      width: 90,
      align: 'center'
    },
    { 
      field: 'card_owner', 
      headerName: 'Owner:', 
      width: 90,
    },
    {
      field: 'card_grade_link', 
      headerName: 'Link:',
      width: 50,
      renderCell: (params) => {
        return (
          <a href={`${params.value}`}>
            [LINK]
          </a>
        );
      }
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`https://api.alientrader.cards/data/dbsearch`, {
          params: { q: query },
        });
        setCardList(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);

  return (
    <div className="">
      <div className='search-db'>
        <input
          className="search"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
      </div>
      <br />
      <div className='card-search-table'>
        <div style={{ height: 800}}>
          <DataGrid 
            rows={cardList} 
            columns={columns} 
            getRowId={(row) => row.card_id}
            rowHeight={110}
            sx={{
              fontSize: 12,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DB;