import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import BannerImage from '../assets/Space-4.jpg'
import Moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './submission.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import SubCardWall from '../card/walls/submission.js'
import SubSoldWall from '../card/walls/subSold.js'
import SubUnsoldWall from '../card/walls/subUnsold.js'

function Submission() {
  const {id} = useParams()
  const [subData, setSubData] = useState([]);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [data, setData] = useState([]);
  const [key, setKey] = useState('cardWall');
  const columns: GridColDef[] = [
    { 
      field: 'card_grade_sub_order', 
      headerName: 'Order', 
      width: 50, 
      align: 'center'
    },
    { 
      field: 'card_sold_price', 
      headerName: 'Sold Price', 
      width: 70,
      align: 'right',
      renderCell: (params) => {
        let price = params.value;
        if (!price) {
          return "-";
        }
        return `$${price.toFixed(2)}`;
      },
    },
        {
          field: 'cover_image',
          headerName: 'Card',
          width: 90,
          align: 'center',
          renderCell: (params) => {
            let parsed = JSON.parse(params.value); // Parse the value into JSON
            let imageUrl;
  
            if (parsed[0]?.thumbnail === "none") {
              imageUrl = "http://alientrader.net/cards/cards/images/cards/Temp.jpg";
            } else {
              if (parsed[0]?.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
                imageUrl = parsed[0]?.thumbnail.replace("/home/u825454141/domains/alientrader.net/public_html/", "http://alientrader.net/");
              } else {
                imageUrl = "https://api.alientrader.cards/" + parsed[0]?.thumbnail;
              }
            }          
            let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
            return (
              <div>
                <a href={`/card/${cardId}`}>
                  <img src={imageUrl} alt="card" style={{height: '100px', borderRadius: '5px'}}/>
                </a>
              </div>
            );
          }
        },
        {
          field: 'card_pokemon', 
          headerName: 'Pokemon',
          width: 110,
          renderCell: (params) => {
            let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
            return (
              <a href={`/card/${cardId}`}>
                {params.value}
              </a>
            );
          }
        },
        { 
          field: 'card_grade_number', 
          headerName: 'Grade #', 
          width: 60,
        },
        { 
          field: 'card_grade', 
          headerName: 'Grade', 
          width: 70,
        },
        { 
          field: 'card', 
          headerName: 'Variety / Pedigree:', 
          width: 120,
        },
        { 
          field: 'card_set_year', 
          headerName: 'Year:', 
          width: 50, 
        },
        { 
          field: 'card_set', 
          headerName: 'Set:', 
          width: 150, 
        },
        { 
          field: 'card_language', 
          headerName: 'Language:', 
          width: 70, 
        },
        { 
          field: 'card_price', 
          headerName: 'Price:', 
          width: 65,
          renderCell: (params) => {
            let price = params.value;
            if (!price) {
              return "$0.00";
            }
            return `$${price.toFixed(2)}`;
          }
        },
        { 
          field: 'card_fee_grade', 
          headerName: 'Grade Fee:', 
          width: 75,
          renderCell: (params) => {
            let price = params.value;
            if (!price) {
              return "$0.00";
            }
            return `$${price.toFixed(2)}`;
          }
        },
        { 
          field: 'card_fee_shiptax', 
          headerName: 'Ship & Tax:', 
          width: 75,
          renderCell: (params) => {
            let price = params.value;
            if (!price) {
              return "$0.00";
            }
            return `$${price.toFixed(2)}`;
          }
        },
        { 
          field: 'card_total_cost', 
          headerName: 'Total Cost:', 
          width: 70,
          renderCell: (params) => {
            let price = params.value;
            if (!price) {
              return "$0.00";
            }
            return `$${price.toFixed(2)}`;
          }
        },
        { 
          field: 'card_date_received', 
          headerName: 'Received Date:', 
          width: 80,
          align: 'right',
          renderCell: (params) => {
            let date = new Date(params.value);
            return date.toLocaleDateString();  // format: "mm/dd/yyyy" 
          }
        },
        { 
          field: 'card_owner', 
          headerName: 'Owner:', 
          width: 85,
        },
  ];

  useEffect(()=> {
    axios.get(`https://api.alientrader.cards/submission/${id}`)
    .then((response)=> {
      setSubData(response.data[0]);
    })
    .catch(error => {
      console.error('Error fetching card data:', error);
    });
  }, [id])

  const subDateMoment = subData.sub_date ? Moment(subData.sub_date) : null;
  const subReturnDateMoment = subData.sub_return_date ? Moment(subData.sub_return_date) : null;
  // Calculate the difference in days
  const subTurnaround = subReturnDateMoment && subDateMoment 
    ? subReturnDateMoment.diff(subDateMoment, 'days') 
    : '-';
  // Now format the dates for display
  const subDate = subDateMoment ? subDateMoment.format('MM-DD-YYYY') : "-";
  const subReturnDate = subReturnDateMoment ? subReturnDateMoment.format('MM-DD-YYYY') : "-";
  const subPreCost = subData.sub_precost ? subData.sub_precost.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  const subShippingTo = subData.sub_shipping_to ? subData.sub_shipping_to.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  const subShippingFrom = subData.sub_shipping_from ? subData.sub_shipping_from.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  const subUpcharge = subData.sub_upcharge ? subData.sub_upcharge.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  const subTotalCost = subData.sub_total_cost ? subData.sub_total_cost.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  const subPSAorderArrived = subData.PSA_Order_Arrived ? Moment(subData.PSA_Order_Arrived).format('MM-DD-YYYY') : "-";
  const subPSAorderPrep = subData.PSA_Order_Prep ? Moment(subData.PSA_Order_Prep).format('MM-DD-YYYY') : "-";  
  const subPSAresearchID = subData.PSA_Research_ID ? Moment(subData.PSA_Research_ID).format('MM-DD-YYYY') : "-";
  const subPSAgrading = subData.PSA_Grading ? Moment(subData.PSA_Grading).format('MM-DD-YYYY') : "-";
  const subPSAassembly = subData.PSA_Assembly ? Moment(subData.PSA_Assembly).format('MM-DD-YYYY') : "-";
  const subPSAgradesReady = subData.PSA_Grades_Ready ? Moment(subData.PSA_Grades_Ready).format('MM-DD-YYYY') : "-";
  const subPSAqaChecks = subData.PSA_QA_Checks ? Moment(subData.PSA_QA_Checks).format('MM-DD-YYYY') : "-";
  const subPSACompleted = subData.PSA_Completed ? Moment(subData.PSA_Completed).format('MM-DD-YYYY') : "-";

  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/cards/${id}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  const [SoldPriceData, setSoldPriceData] = useState([]);
  const SoldPrice = SoldPriceData ? SoldPriceData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/sold/card_sold_price`)
      .then((response) => {
        setSoldPriceData(response.data[0].TotalSoldPrice);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [SoldFees, setSoldFees] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/sold/card_sold_fees`)
      .then((response) => {
        setSoldFees(response.data[0].SubSoldFees);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [SoldShip, setSoldShip] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/sold/card_solds_ship`)
      .then((response) => {
        setSoldShip(response.data[0].SubSoldShip);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const netSalesCalc = SoldPriceData - SoldFees - SoldShip
  const netSales = SoldPrice ? netSalesCalc.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00"
  const [SoldCount, setSoldCount] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/sold/count`)
      .then((response) => {
        setSoldCount(response.data[0].TotalSoldCount);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [SoldProfitData, setSoldProfitData] = useState([]);
  const SoldProfit = SoldProfitData ? SoldProfitData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/sold/card_profit`)
      .then((response) => {
        setSoldProfitData(response.data[0].TotalSoldProfit);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardCostData, setCardCostData] = useState([]);
  const CardCost = CardCostData ? CardCostData.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00";
  const BreakevenCalc = netSalesCalc - CardCostData
  const Breakeven = BreakevenCalc ? BreakevenCalc.toLocaleString('en-US', {style: 'currency', currency: 'USD',}) : "$0.00"
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/card/card_total_cost`)
      .then((response) => {
        setCardCostData(response.data[0].TotalCost);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade10, setCardGrade10] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/10`)
      .then((response) => {
        setCardGrade10(response.data[0].TotalGrade10);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade95, setCardGrade95] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/9-5`)
      .then((response) => {
        setCardGrade95(response.data[0].TotalGrade95);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade9, setCardGrade9] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/9`)
      .then((response) => {
        setCardGrade9(response.data[0].TotalGrade9);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade85, setCardGrade85] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/8-5`)
      .then((response) => {
        setCardGrade85(response.data[0].TotalGrade85);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade8, setCardGrade8] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/8`)
      .then((response) => {
        setCardGrade8(response.data[0].TotalGrade8);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade75, setCardGrade75] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/7-5`)
      .then((response) => {
        setCardGrade75(response.data[0].TotalGrade75);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade7, setCardGrade7] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/7`)
      .then((response) => {
        setCardGrade7(response.data[0].TotalGrade7);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);
  const [CardGrade6, setCardGrade6] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alientrader.cards/submission/${id}/grade/6`)
      .then((response) => {
        setCardGrade6(response.data[0].TotalGrade6);
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id]);

  return (
    <div>
      <div className='cardTopBG' style={{ backgroundImage: `url(${BannerImage})` }}>
        <div className='actions'>
          <div className='goBack' onClick={goBack}><ArrowBackIcon fontSize="large"/></div>
          <div className='cardViewEdit'><Link to={`/dashboard/submissions/${subData.sub_id}/edit`}><EditIcon fontSize="large"/></Link></div>
        </div>
        <div className='subHeader'>{subData.sub_text}</div>
        <div><br /></div>
      </div>
        <div className='actions'>
        </div>
        <div className='subBox'>
        <Container>
            <Row className='d-flex justify-content-center'>
                <Col xs="9" sm="5" lg="5" xl="3" className='custom-info-box shadow-sm'>
                    <Row><div className='custom-info-header'>Submission Info</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Grading Company:</div></Col>
                        <Col><div className='custom-info-val'>{subData.sub_grader}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Order Number:</div></Col>
                        <Col><div className='custom-info-val'>{subData.sub_order_number}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Number of Cards:</div></Col>
                        <Col><div className='custom-info-val'>{subData.sub_total_cards}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Submitted Date:</div></Col>
                        <Col><div className='custom-info-val'>{subDate}</div></Col>
                        </Row>
                        <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Returned Date:</div></Col>
                        <Col><div className='custom-info-val'>{subReturnDate}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Turnaround:</div></Col>
                        <Col><div className='custom-info-val'>{subTurnaround} Days</div></Col>
                    </Row>
                    <Row><br/></Row>
                </Col>
                <Col xs="9" sm="5" lg="3" xl="2" className='custom-info-box shadow-sm'>
                    <Row><div className='custom-info-header'>Grades</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>GEM MINT - 10:</div></Col>
                        <Col xs={2} sm={2}><div className='custom-info-val'>{CardGrade10}</div></Col>
                        <Col xs={4} sm={4}><div className='custom-info-val'></div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>MT - 9:</div></Col>
                        <Col xs={2} sm={2}><div className='custom-info-val'>{CardGrade9}</div></Col>
                        <Col xs={1} sm={1}><div className='custom-info-title'>9.5+</div></Col>
                        <Col xs={3} sm={3}><div className='custom-info-val'>{CardGrade95}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>NM-MT - 8:</div></Col>
                        <Col xs={2} sm={2}><div className='custom-info-val'>{CardGrade8}</div></Col>
                        <Col xs={1} sm={1}><div className='custom-info-title'>8.5+</div></Col>
                        <Col xs={3} sm={3}><div className='custom-info-val'>{CardGrade85}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>NM - 7:</div></Col>
                        <Col xs={2} sm={2}><div className='custom-info-val'>{CardGrade7}</div></Col>
                        <Col xs={1} sm={1}><div className='custom-info-title'>7.5+</div></Col>
                        <Col xs={3} sm={3}><div className='custom-info-val'>{CardGrade75}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Below 7:</div></Col>
                        <Col xs={2} sm={2}><div className='custom-info-val'>{CardGrade6}</div></Col>
                        <Col xs={4} sm={4}><div className='custom-info-val'></div></Col>
                    </Row>
                    <Row><br/></Row>
                </Col>
                <Col xs="9" sm="5" lg="5" xl="3" className='custom-info-box shadow-sm'>
                    <Row><div className='custom-info-header'>Submission Expense</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Submission Cost:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{subPreCost}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Shipping to:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{subShippingTo}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Shipping from:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{subShippingFrom}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Upcharge Cost:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{subUpcharge}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Total Grading Cost:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{subTotalCost}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Total Card Costs:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{CardCost}</div></Col>
                    </Row>
                    <Row><br/></Row>
                </Col>
                <Col xs="9" sm="5" lg="3" xl="2" className='custom-info-box shadow-sm'>
                    <Row><div className='custom-info-header'>Sold Info</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'># of Sold:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{SoldCount}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Total Sales:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{SoldPrice}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Net Sales - Fees:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{netSales}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Profit on Cards Sold:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{SoldProfit}</div></Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col><div className='custom-info-title'>Breakeven:</div></Col>
                        <Col xs={5} sm={5}><div className='custom-info-val'>{Breakeven}</div></Col>
                    </Row>
                    <Row><br/></Row>
                </Col>
            </Row>
          </Container>
          </div>
          <div className='subPositionAdjust'>
          <Container>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col lg={8} className='custom-info-box shadow-sm'>
                <Row>
                  <Tabs id="card-information" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                    <Tab eventKey="cardWall" title="Card Wall">
                      <Row className='subCardWall d-flex align-items-center justify-content-center'>
                        <SubCardWall/>
                      </Row>
                    </Tab>
                    <Tab eventKey="soldWall" title="Sold">
                      <Row className='subCardWall d-flex align-items-center justify-content-center'>
                        <SubSoldWall/>
                      </Row>
                    </Tab>
                    <Tab eventKey="unsoldWall" title="Unsold">
                      <Row className='subCardWall d-flex align-items-center justify-content-center'>
                        <SubUnsoldWall/>
                      </Row>
                    </Tab>
                    {subData && subData.sub_grader === "PSA" && (<Tab eventKey="psa-dates" title="Dates">
                        <Row className='subCardWall d-flex align-items-center justify-content-center'>
                          <Row><div className='custom-info-header'>PSA Date Info</div></Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Order Arrived:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAorderArrived}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Order Prep:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAorderPrep}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Research & ID:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAresearchID}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Grading:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAgrading}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Assembly:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAassembly}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Grades Ready:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAgradesReady}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>QA Checks:</div></Col>
                              <Col><div className='subGraderDates'>{subPSAqaChecks}</div></Col>
                          </Row>
                          <Row className='d-flex align-items-center justify-content-center'>
                              <Col><div className='custom-info-title'>Completed:</div></Col>
                              <Col><div className='subGraderDates'>{subPSACompleted}</div></Col>
                          </Row>
                          <Row><br/></Row>
                        </Row>
                    </Tab>)}
                    {subData && subData.sub_notes && (<Tab eventKey="notes" title="Notes">
                        <Row className='subCardWall d-flex align-items-center justify-content-center'>
                          <Row className='d-flex align-items-center justify-content-center'>
                            <Col xs={2} sm={2}><div className='custom-info-title'>Notes:</div></Col>
                            <Col><div className='custom-info-misc'>{subData.sub_notes}</div></Col>
                          </Row>
                          <Row><br/></Row>
                        </Row>
                    </Tab>)}
                  </Tabs>
                </Row>
              </Col>
            </Row>
            <Row><br/></Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col className='d-flex align-items-center justify-content-center'>
              <div style={{ height: 800, width: '100%'}}>
                <DataGrid 
                  rows={data} 
                  columns={columns} 
                  getRowId={(row) => row.card_id}
                  rowHeight={110}
                  sx={{
                    fontSize: 12,
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        </div>
    </div>
  )
}

export default Submission;
