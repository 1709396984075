import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import './card.css';
import ImageUploadComponent from '../../components/ImageUpload';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { utcToZonedTime, format } from 'date-fns-tz';

const gameOptions = ['Pokemon','Yu-Gi-Oh','Football','Baseball', 'Basketball', 'Magic','Marvel','DC', 'Weiss Schwarz', 'Other'];
const languageOptions = ['English', 'Japanese', 'Chinese', 'Korean', 'Thai', 'Spanish', 'German', 'Italian'];
const graderOptions = ['-', 'PSA','CGC','CGC-Q','AGS', 'SCG', 'TCCG', 'TAG','GMA', 'Not Graded'];
const gradeOptions = [ '-', 'GEM MINT','MINT','NEAR MINT - MINT','NEAR MINT','EXCELLENT - MINT','EXCELLENT','VERY GOOD - EXCELLENT','VERY GOOD','GOOD','FAIR','POOR','PERFECT','PRISTINE','MINT+','NEAR MINT - MINT+','EXCELLENT - MINT+','EXCELLENT+','VERY GOOD - EXCELLENT+','VERY GOOD+','GOOD+','NEAR MINT+']

function Card() {
  const { id } = useParams();
  const [cardID, setCardID] = useState(null);
  const [key, setKey] = useState('info');
  const navigate = useNavigate();
  const [updateMessage, setUpdateMessage] = useState('');
  const [cardGame, setCardGame] = useState('');
  const [cardPokemon, setCardPokemon] = useState('');
  const [cardVariety, setCardVariety] = useState('');
  const [cardLanguage, setCardLanguage] = useState('');
  const [cardSetYear, setCardSetYear] = useState('');
  const [cardSet, setCardSet] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardOwner, setCardOwner] = useState('');
  const [cardGrader, setCardGrader] = useState('');
  const [cardGradeNumber, setCardGradeNumber] = useState('');
  const [cardGrade, setCardGrade] = useState('');
  const [cardGradeCert, setCardGradeCert] = useState('');
  const [cardGradeCertCracked, setCardGradeCertCracked] = useState('');
  const [cardGradeCertCrackedID, setCardGradeCertCrackedID] = useState('');
  const [cardGradeCertCrackedIDNew, setCardGradeCertCrackedIDNew] = useState('');
  const [cardGradeLink, setCardGradeLink] = useState('');
  const [cardGradeSub, setCardGradeSub] = useState('');
  const [cardGradeSubOrder, setCardGradeSubOrder] = useState('');
  const [cardGradeCentering, setCardGradeCentering] = useState('');
  const [cardPreGrade, setCardPreGrade] = useState('');
  const [cardPrice, setCardPrice] = useState('');
  const [cardFeeGrade, setCardFeeGrade] = useState('');
  const [cardFeeShipTax, setCardFeeShipTax] = useState('');
  const [cardPurchasedAt, setCardPurchasedAt] = useState('');
  const [cardDateReceived, setCardDateReceived] = useState('');
  const [cardSoldPrice, setCardSoldPrice] = useState('');
  const [cardSoldFees, setCardSoldFees] = useState('');
  const [cardSoldFeesAds, setCardSoldFeesAds] = useState('');
  const [cardSoldShip, setCardSoldShip] = useState('');
  const [cardSoldDate, setCardSoldDate] = useState('');
  const [cardSoldAt, setCardSoldAt] = useState('');
  const [cardNotes, setCardNotes] = useState('');
  const [cardLink, setCardLink] = useState('');
  const [cardEbayListing, setCardEbayListing] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [receivedImageUrls, setReceivedImageUrls] = useState({});
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    Axios.get(`https://api.alientrader.cards/card/${id}`)
      .then((response) => {
        setCardID(response.data);
        setCardGame(response.data[0].card_game);
        setCardPokemon(response.data[0].card_pokemon);
        setCardVariety(response.data[0].card);
        setCardLanguage(response.data[0].card_language);
        setCardSetYear(response.data[0].card_set_year);
        setCardSet(response.data[0].card_set);
        setCardNumber(response.data[0].card_number);
        setCardOwner(response.data[0].card_owner);
        setCardGrader(response.data[0].card_grader);
        setCardGradeNumber(response.data[0].card_grade_number);
        setCardGrade(response.data[0].card_grade);
        setCardGradeCert(response.data[0].card_grade_cert);
        setCardGradeCertCracked(response.data[0].card_grade_cert_cracked);
        setCardGradeCertCrackedID(response.data[0].card_grade_cert_cracked_ID);
        setCardGradeCertCrackedIDNew(response.data[0].card_grade_cert_cracked_ID_new);
        setCardGradeLink(response.data[0].card_grade_link);
        setCardGradeSub(response.data[0].card_grade_sub);
        setCardGradeSubOrder(response.data[0].card_grade_sub_order);
        setCardGradeCentering(response.data[0].card_grade_centering);
        setCardPreGrade(response.data[0].card_pregrade);
        setCardPrice(response.data[0].card_price);
        setCardFeeGrade(response.data[0].card_fee_grade);
        setCardFeeShipTax(response.data[0].card_fee_shiptax);
        setCardPurchasedAt(response.data[0].card_purchased_at);
        if (response.data[0].card_date_received) {
          setCardDateReceived(convertISOToDateInputValue(response.data[0].card_date_received));
        }
        setCardSoldPrice(response.data[0].card_sold_price);
        setCardSoldFees(response.data[0].card_sold_fees);
        setCardSoldFeesAds(response.data[0].card_sold_fees_ads);
        setCardSoldShip(response.data[0].card_solds_ship);
        if (response.data[0].card_sold_date) {
          setCardSoldDate(convertISOToDateInputValue(response.data[0].card_sold_date));
        }
        setCardSoldAt(response.data[0].card_sold_at);
        setCardNotes(response.data[0].card_notes);
        setCardLink(response.data[0].card_link);
        setCardEbayListing(response.data[0].ebay_listing)
      })
      .catch((error) => {
        console.error('Error fetching card data:', error);
      });
  }, [id]);

  useEffect(() => {
    // Fetch submissions when the component mounts
    Axios.get('https://api.alientrader.cards/card/add/submissions')
    .then(response => {
      const data = response.data; // Extract the data from the Axios response
      setSubmissions(data);
    })
    .catch(error => {
      console.error("Failed to fetch submissions:", error);
    });
  }, []);
  
  if (!cardID) {
    return <div>Card {id} Loading...</div>;
  }

  const cardData = cardID[0];

  const getWisconsinDate = () => {
    const timeZone = 'America/Los_Angeles';
    const now = new Date();
    const zonedDate = utcToZonedTime(now, timeZone);
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });
  };

  const handleSaveCard = () => {
    const updatedCardData = {
      card_game: cardGame,
      card_pokemon: cardPokemon,
      card: cardVariety,
      card_language: cardLanguage,
      card_set_year: cardSetYear,
      card_set: cardSet,
      card_number: cardNumber,
      card_owner: cardOwner,
      card_grader: cardGrader,
      card_grade_number: cardGradeNumber,
      card_grade: cardGrade,
      card_grade_cert: cardGradeCert,
      card_grade_cert_cracked: cardGradeCertCracked,
      card_grade_cert_cracked_ID: cardGradeCertCrackedID,
      card_grade_cert_cracked_ID_new: cardGradeCertCrackedIDNew,
      card_grade_link: cardGradeLink,
      card_grade_sub: cardGradeSub,
      card_grade_sub_order: cardGradeSubOrder,
      card_grade_centering: cardGradeCentering,
      card_pregrade: cardPreGrade,
      card_price: cardPrice,
      card_fee_grade: cardFeeGrade,
      card_fee_shiptax: cardFeeShipTax,
      card_purchased_at: cardPurchasedAt,
      card_date_received: cardDateReceived,
      card_sold_price: cardSoldPrice,
      card_sold_fees: cardSoldFees,
      card_sold_fees_ads: cardSoldFeesAds,
      card_solds_ship: cardSoldShip,
      card_sold_date: cardSoldDate,
      card_sold_at: cardSoldAt,
      card_notes: cardNotes,
      card_link: cardLink,
      ebay_listing: cardEbayListing,
      updated_date: getWisconsinDate(),
      //...receivedImageUrls
    };
      // Update the cardData state with the updatedCardData
      setCardID((prevData) => ({
        ...prevData,
        ...updatedCardData,
      }));
    
      Axios.post(`https://api.alientrader.cards/edit/card/${cardData.card_id}`, updatedCardData)
      .then((response) => {
        console.log('Card information updated successfully:', response.data);
        alert('Card saved successfully!');
        setUpdateMessage('Card information updated successfully! Redirecting...');
        navigate(-1);
        })
        .catch((error) => {
          console.error('Error updating card information:', error);
        });
    };
  function convertISOToDateInputValue(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
      
    return `${year}-${month}-${day}`;
  }

  //Card Images
let modifiedGraderFrontData;
if (cardData.grader_front_url && cardData.grader_front_url !== "undefined") {
  const imageGraderFrontData = JSON.parse(cardData.grader_front_url);
  modifiedGraderFrontData = imageGraderFrontData.map((image) => {
    const modifiedGraderFrontData = { ...image };
    // Modify .name property
    if (modifiedGraderFrontData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedGraderFrontData.name = modifiedGraderFrontData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedGraderFrontData.name = "https://api.alientrader.cards/" + modifiedGraderFrontData.name;
    }
    // Modify .thumbnail property if it exists
    if (modifiedGraderFrontData.thumbnail) {
      if (modifiedGraderFrontData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedGraderFrontData.thumbnail = modifiedGraderFrontData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedGraderFrontData.thumbnail = "https://api.alientrader.cards/" + modifiedGraderFrontData.thumbnail;
      }
    }
    return modifiedGraderFrontData;
  });
}
let modifiedGraderBackData;
if (cardData.grader_back_url && cardData.grader_back_url !== "undefined") {
  const imageGraderBackData = JSON.parse(cardData.grader_back_url);
  modifiedGraderBackData = imageGraderBackData.map((image) => {
    const modifiedGraderBackData = { ...image };
    // Modify .name property
    if (modifiedGraderBackData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedGraderBackData.name = modifiedGraderBackData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedGraderBackData.name = "https://api.alientrader.cards/" + modifiedGraderBackData.name;
    }
    // Modify .thumbnail property if it exists
    if (modifiedGraderBackData.thumbnail) {
      if (modifiedGraderBackData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedGraderBackData.thumbnail = modifiedGraderBackData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedGraderBackData.thumbnail = "https://api.alientrader.cards/" + modifiedGraderBackData.thumbnail;
      }
    }
    return modifiedGraderBackData;
  });
}
let modifiedScanFrontData;
if (cardData.scan_front_url && cardData.scan_front_url !== "undefined") {
  const imageScanFrontData = JSON.parse(cardData.scan_front_url);
  modifiedScanFrontData = imageScanFrontData.map((image) => {
    const modifiedScanFrontData = { ...image };
    // Modify .name property
    if (modifiedScanFrontData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedScanFrontData.name = modifiedScanFrontData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedScanFrontData.name = "https://api.alientrader.cards/" + modifiedScanFrontData.name;
    }
    // Modify .thumbnail property if it exists
    if (modifiedScanFrontData.thumbnail) {
      if (modifiedScanFrontData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedScanFrontData.thumbnail = modifiedScanFrontData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedScanFrontData.thumbnail = "https://api.alientrader.cards/" + modifiedScanFrontData.thumbnail;
      }
    }
    return modifiedScanFrontData;
  });
}
let modifiedScanBackData;
if (cardData.scan_back_url && cardData.scan_back_url !== "undefined") {
  const imageScanBackData = JSON.parse(cardData.scan_back_url);
  modifiedScanBackData = imageScanBackData.map((image) => {
    const modifiedScanBackData = { ...image };
    if (modifiedScanBackData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedScanBackData.name = modifiedScanBackData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedScanBackData.name = "https://api.alientrader.cards/" + modifiedScanBackData.name;
    }
    if (modifiedScanBackData.thumbnail) {
      if (modifiedScanBackData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedScanBackData.thumbnail = modifiedScanBackData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedScanBackData.thumbnail = "https://api.alientrader.cards/" + modifiedScanBackData.thumbnail;
      }
    }
    return modifiedScanBackData;
  });
}
let modifiedAlienTraderFrontData;
if (cardData.alientrader_front_url && cardData.alientrader_front_url !== "undefined") {
  const imageAlienTraderFrontData = JSON.parse(cardData.alientrader_front_url);
  modifiedAlienTraderFrontData = imageAlienTraderFrontData.map((image) => {
    const modifiedAlienTraderFrontData = { ...image };
    if (modifiedAlienTraderFrontData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedAlienTraderFrontData.name = modifiedAlienTraderFrontData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedAlienTraderFrontData.name = "https://api.alientrader.cards/" + modifiedAlienTraderFrontData.name;
    }
    if (modifiedAlienTraderFrontData.thumbnail) {
      if (modifiedAlienTraderFrontData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedAlienTraderFrontData.thumbnail = modifiedAlienTraderFrontData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedAlienTraderFrontData.thumbnail = "https://api.alientrader.cards/" + modifiedAlienTraderFrontData.thumbnail;
      }
    }
    return modifiedAlienTraderFrontData;
  });
}
let modifiedAlienTraderBackData;
if (cardData.alientrader_back_url && cardData.alientrader_back_url !== "undefined") {
  const imageAlienTraderBackData = JSON.parse(cardData.alientrader_back_url);
  modifiedAlienTraderBackData = imageAlienTraderBackData.map((image) => {
    const modifiedAlienTraderBackData = { ...image };
    if (modifiedAlienTraderBackData.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedAlienTraderBackData.name = modifiedAlienTraderBackData.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedAlienTraderBackData.name = "https://api.alientrader.cards/" + modifiedAlienTraderBackData.name;
    }
    if (modifiedAlienTraderBackData.thumbnail) {
      if (modifiedAlienTraderBackData.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedAlienTraderBackData.thumbnail = modifiedAlienTraderBackData.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedAlienTraderBackData.thumbnail = "https://api.alientrader.cards/" + modifiedAlienTraderBackData.thumbnail;
      }
    }
    return modifiedAlienTraderBackData;
  });
}
let modifiedMiscImage1Data;
if (cardData.misc_image_url && cardData.misc_image_url !== "undefined") {
  const imageMiscImage1Data = JSON.parse(cardData.misc_image_url);
  modifiedMiscImage1Data = imageMiscImage1Data.map((image) => {
    const modifiedMiscImage1Data = { ...image };
    if (modifiedMiscImage1Data.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedMiscImage1Data.name = modifiedMiscImage1Data.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedMiscImage1Data.name = "https://api.alientrader.cards/" + modifiedMiscImage1Data.name;
    }
    if (modifiedMiscImage1Data.thumbnail) {
      if (modifiedMiscImage1Data.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedMiscImage1Data.thumbnail = modifiedMiscImage1Data.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedMiscImage1Data.thumbnail = "https://api.alientrader.cards/" + modifiedMiscImage1Data.thumbnail;
      }
    }
    return modifiedMiscImage1Data;
  });
}
let modifiedMiscImage2Data;
if (cardData.misc_image2_url && cardData.misc_image2_url !== "undefined") {
  const imageMiscImage2Data = JSON.parse(cardData.misc_image2_url);
  modifiedMiscImage2Data = imageMiscImage2Data.map((image) => {
    const modifiedMiscImage2Data = { ...image };
    if (modifiedMiscImage2Data.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedMiscImage2Data.name = modifiedMiscImage2Data.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedMiscImage2Data.name = "https://api.alientrader.cards/" + modifiedMiscImage2Data.name;
    }
    if (modifiedMiscImage2Data.thumbnail) {
      if (modifiedMiscImage2Data.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedMiscImage2Data.thumbnail = modifiedMiscImage2Data.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedMiscImage2Data.thumbnail = "https://api.alientrader.cards/" + modifiedMiscImage2Data.thumbnail;
      }
    }
    return modifiedMiscImage2Data;
  });
}
let modifiedMiscImage3Data;
if (cardData.misc_image3_url && cardData.misc_image3_url !== "undefined") {
  const imageMiscImage3Data = JSON.parse(cardData.misc_image3_url);
  modifiedMiscImage3Data = imageMiscImage3Data.map((image) => {
    const modifiedMiscImage3Data = { ...image };
    if (modifiedMiscImage3Data.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
      modifiedMiscImage3Data.name = modifiedMiscImage3Data.name.replace(
        "/home/u825454141/domains/alientrader.net/public_html/",
        "http://alientrader.net/"
      );
    } else {
      modifiedMiscImage3Data.name = "https://api.alientrader.cards/" + modifiedMiscImage3Data.name;
    }
    if (modifiedMiscImage3Data.thumbnail) {
      if (modifiedMiscImage3Data.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedMiscImage3Data.thumbnail = modifiedMiscImage3Data.thumbnail.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedMiscImage3Data.thumbnail = "https://api.alientrader.cards/" + modifiedMiscImage3Data.thumbnail;
      }
    }
    return modifiedMiscImage3Data;
  });
}

  return (
    <div className='card'>
      <div className='cardID'>Card #{cardData.card_id}</div>
      <div className='card-image'>
        <Container>
        <Row className='justify-content-center'>
          {cardData.grader_front_url && modifiedGraderFrontData.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Grader Front</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.grader_back_url && modifiedGraderBackData.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row><div className='custom-info-header'>Grader Back</div></Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.scan_front_url && modifiedScanFrontData.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Scan Front</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.scan_back_url && modifiedScanBackData.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Scan Back</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.alientrader_front_url && modifiedAlienTraderFrontData.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Alien Trader Front</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.alientrader_back_url && modifiedAlienTraderBackData.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Alien Trader Back</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.misc_image_url && modifiedMiscImage1Data.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Miscellaneous</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.misc_image2_url && modifiedMiscImage2Data.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Miscellaneous</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          {cardData.misc_image3_url && modifiedMiscImage3Data.map((image, index) => (
            <Col key={cardData.card_id} sm={2}>
              <Row>
                <div className='custom-info-header'>Miscellaneous</div>
              </Row>
              <Row className='edit-card-images'><a href={image.name} data-title={image.userNme}><img src={image.thumbnail} alt={image.usrName} className='editCardImageDisplay shadow' /></a></Row>
            </Col>
          ), [])}
          </Row>
        </Container>
      </div>
      <div className='card-set'>{cardData.card_set_year} {cardData.card_game} {cardData.card_set}</div>
      <div className='card-title'>{cardData.card_pokemon} {cardData.card}</div>
      <Tabs id="card-information" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
      <Tab eventKey="info" title="Main Info">
      <div className='actions'>
        <div className='updateMessage'>
          {updateMessage && <div>{updateMessage}</div>}
        </div>
        <div className='cardEdit'>
          <button onClick={handleSaveCard} className='edit-button'><SaveIcon fontSize="large"/></button>
          <Link to={`/card/${cardData.card_id}`}><CancelIcon fontSize="large" /></Link>
        </div>
      </div>
      <Container>
      <Row>
          <Col sm className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Card Info</div></Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'>Game:</Col>
              <Col>
                <div className='custom-input-val'>
                  <select
                    value={cardGame}
                    id={`card_game_${cardData.card_id}`}
                    className='edit-input'
                    onChange={(e) => setCardGame(e.target.value)}
                    >
                    {gameOptions.map((game, index) => (
                      <option key={index} value={game}>
                        {game}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'>Pokemon / Character / Player:</Col>
              <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardPokemon}
                        id={`card_pokemon_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardPokemon(inputValue === '' ? null : inputValue);
                        }}
                    />
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'>Variety / Pedigree:</Col>
              <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardVariety}
                        id={`card_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardVariety(inputValue === '' ? null : inputValue);
                        }}
                      />
                    </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'>Language:</Col>
              <Col>
                <div className='custom-input-val'>
                  <select
                    value={cardLanguage}
                    id={`card_language_${cardData.card_id}`}
                    className='edit-input'
                    onChange={(e) => setCardLanguage(e.target.value)}
                  >
                    {languageOptions.map((language, index) => (
                      <option key={index} value={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'><div>Year:</div></Col>
              <Col>
                <div className='custom-input-val'>
                  <input
                    type="text"
                    value={cardSetYear}
                    id={`card_set_year_${cardData.card_id}`}
                    className='edit-input'
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setCardSetYear(inputValue === '' ? null : inputValue);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Set:</div></Col>
              <Col>
              <div className='custom-input-val'>
                <input
                        type="text"
                        value={cardSet}
                        id={`card_set_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardSet(inputValue === '' ? null : inputValue);
                        }}
                    />
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Card Number:</div></Col>
                <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardNumber}
                        id={`card_number_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardNumber(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Owner:</div></Col>
                <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardOwner}
                        id={`card_owner_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardOwner(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <br />
          </Col>
          <Col sm className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Grading Info</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Grader:</div></Col>
            <Col>
              <div className='custom-input-val'>
                  <select
                    value={cardGrader}
                    id={`card_grader_${cardData.card_id}`}
                    placeholder='Select Grading Company'
                    className='edit-input'
                    onChange={(e) => setCardGrader(e.target.value)}
                  >
                    {graderOptions.map((grader, index) => (
                      <option key={index} value={grader}>
                        {grader}
                      </option>
                    ))}
                  </select>
                </div>
            </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'><div>Grade Number:</div></Col>
              <Col>
                <div className='custom-input-val'>
                <input
                        type="text"
                        value={cardGradeNumber}
                        id={`card_grade_number_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeNumber(inputValue === '' ? null : inputValue);
                        }}
                    />
                </div>
            </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Grade:</div></Col>
                <Col>
                  <div className='custom-input-val'>
                    <select
                      value={cardGrade}
                      id={`card_grade_${cardData.card_id}`}
                      className='edit-input'
                      placeholder='Select Grade'
                      onChange={(e) => setCardGrade(e.target.value)}
                    >
                      {gradeOptions.map((grade, index) => (
                        <option key={index} value={grade}>
                          {grade}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Certification #:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardGradeCert}
                        id={`card_grade_cert_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeCert(inputValue === '' ? null : inputValue);
                        }}

                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Link:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardGradeLink}
                        id={`card_grade_link_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeLink(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Submission:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                      <select 
                        value={cardGradeSub} 
                        className='edit-input'
                        onChange={(e) => setCardGradeSub(e.target.value)}
                      >
                        {submissions.map(sub => (
                          <option key={sub.sub_id} value={sub.sub_id}>
                          {sub.sub_text}
                          </option>
                        ))}
                      </select>
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Submission Order:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardGradeSubOrder}
                        id={`card_grade_sub_order_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeSubOrder(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Centering:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardGradeCentering}
                        id={`card_grade_centering_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeCentering(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Pre-Grade:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardPreGrade}
                        id={`card_pregrade_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardPreGrade(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm className='custom-info-box shadow-sm'>
            <Row><div className='custom-info-header'>Cost Info</div></Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Cost: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardPrice}
                        id={`card_price_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardPrice(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Grading Fees: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardFeeGrade}
                        id={`card_fee_grade_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardFeeGrade(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Ship & Tax: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardFeeShipTax}
                        id={`card_fee_shiptax_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardFeeShipTax(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Total Cost: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                        <input
                            type="text"
                            value={cardData.card_total_cost}
                            id="card_total_cost"
                            className='edit-input'
                            disabled
                        />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Recieved Date:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                        <input
                            type="date"
                            value={cardDateReceived}
                            id={`card_date_received_${cardData.card_id}`}
                            className='edit-input'
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setCardDateReceived(inputValue === '' ? null : inputValue);
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={4} className='custom-info-title'>Purchased At:</Col>
              <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardPurchasedAt}
                        id={`card_${cardData.card_purchased_at}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardPurchasedAt(inputValue === '' ? null : inputValue);
                        }}
                      />
                    </div>
              </Col>
            </Row>
            <br />
          </Col>
          <Col sm className='custom-info-box shadow-sm'>
          <Row><div className='custom-info-header'>Sold Info</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Sold Price: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardSoldPrice}
                        id={`card_sold_price_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardSoldPrice(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Sold Fees: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardSoldFees}
                        id={`card_sold_fees_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardSoldFees(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Sold Ad Fees: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardSoldFeesAds}
                        id={`card_sold_fees_ads_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardSoldFeesAds(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Shipping Fees: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardSoldShip}
                        id={`card_solds_ship_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardSoldShip(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Profit: $</div></Col>
                <Col>
                    <div className='custom-input-val'>
                        <input
                            type="text"
                            value={cardData.card_profit}
                            id="card_profit"
                            className='edit-input'
                            disabled
                        />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={4} className='custom-info-title'><div>Sold Date:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                            type="date"
                            id={`card_sold_date_${cardData.card_id}`}
                            className='edit-input'
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setCardSoldDate(inputValue === '' ? null : inputValue);
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={4} className='custom-info-title'><div>Sold At:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardSoldAt}
                        id={`card_sold_at_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardSoldAt(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <br />
          </Col>
          <Col lg className='custom-note-box shadow-sm'>
          <Row><div className='custom-info-header'>Notes</div></Row>
          <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={3} className='custom-info-title'><div>eBay Listing:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardEbayListing}
                        id={`ebay_listing_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardEbayListing(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={3} className='custom-info-title'><div>Card Link:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardLink}
                        id={`card_link_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardLink(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xs={3} className='custom-info-title'>Old Cracked ID:</Col>
              <Col>
                <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardGradeCertCrackedID}
                        id={`card_${cardData.card_grade_cert_cracked_ID}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeCertCrackedID(inputValue === '' ? null : inputValue);
                        }}
                      />
                    </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={3} className='custom-info-title'><div>New Cracked ID:</div></Col>
                <Col>
                    <div className='custom-input-val'>
                    <input
                        type="text"
                        value={cardGradeCertCrackedIDNew}
                        id={`cardGradeCertCrackedIDNew_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardGradeCertCrackedIDNew(inputValue === '' ? null : inputValue);
                        }}
                    />
                    </div>
                </Col>
            </Row>
          <Row className='d-flex align-items-center justify-content-center'>
              <Col>
              <div className='custom-input-val'>
                    <textarea
                        type="textarea"
                        value={cardNotes}
                        id={`card_notes_${cardData.card_id}`}
                        className='edit-input'
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setCardNotes(inputValue === '' ? null : inputValue);
                        }}
                    />
                </div>
                <br />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      </Tab>
      <Tab eventKey="images" title="Images">
        <Container>
          <Row className='justify-content-center'>
            <ImageUploadComponent cardId={cardData.card_id} onUrlsReceived={setReceivedImageUrls} />
          </Row>
        </Container>
      </Tab>
      </Tabs>
      <br />
      <div className='cardIcon'>
        <button onClick={handleSaveCard} className='edit-button'><SaveIcon fontSize="large"/></button>
        <Link to={`/card/${cardData.card_id}`}><CancelIcon fontSize="large" /></Link>
      </div>
      <br />
      {updateMessage && <div>{updateMessage}</div>}
    </div>
  )
}

export default Card