import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider"; // adjust path as necessary

const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check user and role after initial render
    if (!loading && (!user || user.role !== requiredRole)) {
      navigate("/");
    }
  }, [loading, user, navigate, requiredRole]); // Include all dependencies

  // While loading or when user is valid, render children
  if (loading || (user && user.role === requiredRole)) {
    return children;
  }

  // Otherwise don't render anything (will redirect due to useEffect)
  return null;
};

export default ProtectedRoute;
