import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom"; // Import Link from 'react-router-dom'
import "./search.css";

function Search() {
  const [cardList, setCardList] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`https://api.alientrader.cards/search`, {
          params: { q: query },
        });
        setCardList(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);

  return (
    <div className="app">
      <input
        className="search"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Game</th>
            <th>Card</th>
            <th>Kind</th>
            <th>Set</th>
            <th>Language</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          {cardList.map((item) => (
            <tr key={item.card_id}>
              <td>
                <Link to={`https://alientrader.co/card/${item.card_id}`}>
                  {item.card_id}
                </Link>
              </td>
              <td>{item.card}</td>
              <td>{item.card_game}</td>
              <td>{item.card_pokemon}</td>
              <td>{item.card}</td>
              <td>{item.card_set}</td>
              <td>{item.card_language}</td>
              <td>{item.card_number}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Search;