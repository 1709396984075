import './card.css'
import React, { useState, useEffect } from "react";
import Axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoverDisplay from '../components/ImagesPage';
import Icons from '../dashboard/components/pokemon.js'
import { MutatingDots } from 'react-loader-spinner'


function Images() {
  const [cardList, setCardList] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20000;
  const query =""

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`https://api.alientrader.cards/data/dbsearch`, {
          params: { q: query },
        });
        setCardList(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);

  // handle page change

  // calculate items for current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cardList.slice(indexOfFirstItem, indexOfLastItem);

  // calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(cardList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="imagesPage">
      {loading ? ( // Show loading indicator when loading is true
        <div className="loader-container">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#4fa94d"
            secondaryColor="#4fa94d"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
      <Container>
        <Row className='d-flex justify-content-center'>
          <Icons />
        </Row>
        <Row><br/></Row>
        <Row>
          {currentItems.map((item, index) => (
            <Col className='imagesPageRow d-flex align-items-center justify-content-center' key={item.card_id}>
                <CoverDisplay key={index} image={item}/>
            </Col>
          ))}
        </Row>
      </Container>
      )}
      <br />
    </div>
  );
}

export default Images;