import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/Space-4.jpg";
import CardStack from "../../assets/Stack.jpg"
import "./Home.css";
//import CardWall from '../card/images.js'
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

function Home() {
  return (
    <div>
      <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
        <div className="headerContainer">
          <h1> Alien Trader Co </h1>
          <br />
          <Link to="/menu">
            <button> eBay Store </button>
          </Link>
        </div>
      </div>
      <Container>
        <Row className='welcomeMessage d-flex justify-content-center shadow-sm'>
          <div>
            Welcome to Alien Trader Co, where we're boldly going where no collector has gone before! We're a team of intergalactic traders on a mission to bring you the most out-of-this-world trading cards, sports cards, and collectibles. We promise that our prices are not from another planet, and our selection is simply out of this world. So, come on in and browse our collection - we promise it won't abduct all your cash!
          </div>
        </Row>
        <Row>
          <div className="cardStack" style={{ backgroundImage: `url(${CardStack})` }}>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
