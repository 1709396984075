import React from 'react'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Charizard from '../../../assets/gen3/charizard.png'
import Kabutops from '../../../assets/gen3/kabutops.png'
import Mewtwo from '../../../assets/gen3/mewtwo.png'
import Mew from '../../../assets/gen3/mew.png'
import Blastoise from '../../../assets/gen3/blastoise.png'
import Venusaur from '../../../assets/gen3/venusaur.png'
import Pikachu from '../../../assets/gen3/pikachu.png'

function pokemon() {
  return (
    <Row className='d-flex justify-content-center'>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/venusaur'><img src={Venusaur} alt="Venusaur" height="70" className='spriteImages'/></Link>
          </Col>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/charizard'><img src={Charizard} alt="Charizard" height="70" className='spriteImages'/></Link>
          </Col>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/blastoise'><img src={Blastoise} alt="Blastoise" height="70" className='spriteImages'/></Link>
          </Col>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/pikachu'><img src={Pikachu} alt="Pikachu" height="70" className='spriteImages'/></Link>
          </Col>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/kabutops'><img src={Kabutops} alt="Kabutops" height="70" className='spriteImages' /></Link>
          </Col>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/mewtwo'><img src={Mewtwo} alt="Mewtwo" height="70" className='spriteImages' /></Link>
          </Col>
          <Col xs={1} className='imagesPageRow d-flex align-items-center justify-content-center'>
            <Link to='/dashboard/card-wall/mew'><img src={Mew} alt="Mew" height="70" className='spriteImages' /></Link>
          </Col>
    </Row>
  )
}

export default pokemon