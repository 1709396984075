import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom'
import Axios from 'axios';
import './sold.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Sold() {
  const [data, setData] = useState([]);

  const columns: GridColDef[] = [
    { 
      field: 'card_id', 
      headerName: 'ID', 
      width: 50, 
      align: 'center'
    },  
    {
      field: 'cover_image',
      headerName: 'Card',
      width: 90,
      align: 'center',
      renderCell: (params) => {
        let parsed = JSON.parse(params.value); // Parse the value into JSON
        let imageUrl;

        if (parsed[0]?.thumbnail === "none") {
          imageUrl = "http://alientrader.net/cards/cards/images/cards/Temp.jpg";
        } else {
          if (parsed[0]?.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
            imageUrl = parsed[0]?.thumbnail.replace("/home/u825454141/domains/alientrader.net/public_html/", "http://alientrader.net/");
          } else {
            imageUrl = "https://api.alientrader.cards/" + parsed[0]?.thumbnail;
          }
        }          
        let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
        return (
          <div>
            <a href={`/card/${cardId}`}>
              <img src={imageUrl} alt="card" style={{height: '100px', borderRadius: '5px'}}/>
            </a>
          </div>
        );
      }
    },
      {
        field: 'card_pokemon', 
        headerName: 'Pokemon',
        width: 110,
        renderCell: (params) => {
          let cardId = params.row.card_id; // Replace 'id' with the correct property name for the card ID
          return (
            <a href={`/card/${cardId}`}>
              {params.value}
            </a>
          );
        }
      },
      
      { 
        field: 'card_profit', 
        headerName: 'Profit', 
        width: 80,
        headerAlign: 'center',
        align: 'right',
        valueFormatter: (params) => `${params.value.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}`,
      },
      { 
        field: 'card_sold_price', 
        headerName: 'Sold Price', 
        width: 90,
        headerAlign: 'center',
        align: 'right',
        valueFormatter: (params) => `${params.value.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}`,
      },
      { 
        field: 'card_price', 
        headerName: 'Cost', 
        width: 80,
        headerAlign: 'center',
        align: 'right',
        valueFormatter: (params) => `${params.value.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}`,
      },
      { 
        field: 'card_sold_fees', 
        headerName: 'Fees', 
        width: 80,
        headerAlign: 'center',
        align: 'right',
        valueFormatter: (params) => `${params.value.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}`,
      },
      { 
        field: 'card_solds_ship', 
        headerName: 'Ship Fee', 
        width: 80,
        headerAlign: 'center',
        align: 'right',
        valueFormatter: (params) => `${params.value.toLocaleString('en-US', {style: 'currency', currency: 'USD',})}`,
      },
      { 
        field: 'card_sold_date', 
        headerName: 'Date Sold', 
        width: 100,
        headerAlign: 'center',
        align: 'right',
        renderCell: (params) => {
          let date = new Date(params.value);
          return date.toLocaleDateString();  // format: "mm/dd/yyyy" 
        }
      },
      { 
        field: 'card_sold_at', 
        headerName: 'Sold At', 
        width: 125,
        headerAlign: 'center',
        align: 'center', 
      },      
    ];
    //Card Info
    useEffect(() => {
        Axios.get('https://api.alientrader.cards/sold')
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.error(`Error fetching data: ${error}`);
        });
    }, []);

    return (
        <div className='sold'>
          <div><h1><Link to='/dashboard/sold'>Sold Cards</Link></h1></div>
          <Container fluid='xxl'>
            <Row className='d-flex justify-content-center'>
              <Col>
                <div style={{ height: 500, width: '100%'}}>
                  <DataGrid 
                    rows={data} 
                    columns={columns} 
                    getRowId={(row) => row.card_id} 
                    rowHeight={110}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    );
};

export default Sold;
