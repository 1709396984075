import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from './context/ProtectedRoute';

//Pages
import Card from './pages/card/card'
import CardEdit from './pages/card/edit'
import CardAdd from './pages/card/add'
import DB from './pages/db/db.tsx'
import Home from './pages/home/home'
import Login from './pages/auth/login/login'
import Logout from './pages/auth/logout/logout'
import Register from './pages/auth/register/register'
import Search from './pages/search/search'
import SearchWall from './pages/card/walls/searchWall.js'
import CardSearch from './pages/cardSearch/cardSearch'
import Dashboard from './pages/dashboard/dashboard';
import SoldReport from './pages/dashboard/sold/sold.tsx'
import Financials from './pages/dashboard/financials/financial.js'
import YearlyReports from './pages/dashboard/financials/year.jsx'
import DashboardCards from './pages/dashboard/cards/cards.tsx'
import DashReports from'./pages/dashboard/reports/reports.tsx'
import DashNewCards from './pages/dashboard/cards/cardsNew.tsx'
import DashImages from './pages/card/images.js'
import CardWallGrader from './pages/card/walls/grader.js'
import CardWall from './pages/card/walls/pokemon.js'
import CardWallGame from './pages/card/walls/game.js'
import CardWallLanguage from './pages/card/walls/language'
import CardWallSet from './pages/card/walls/set'
import CardWallSetYear from './pages/card/walls/setYear'
import CardWallOwner from './pages/card/walls/owner'
import DashSubmissions from './pages/submission/submissions.tsx'
import DashSubmission from './pages/submission/submission.tsx'
import DashSubAdd from './pages/submission/subAdd.js'
import DashSubEdit from './pages/submission/subEdit.js'


//Layouts
import RootLayout from './layouts/RootLayout'


const router = createBrowserRouter([
  {
    path: "/",
    element: <div><RootLayout /></div>,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/card/:id",
        element: <Card />
      },
      {
        path: "/edit/card/:id",
        element: (<ProtectedRoute requiredRole="admin"><CardEdit /></ProtectedRoute>),
      },
      {
        path: "/db",
        element: <DB />
      },
      {
        path: "/search",
        element: <Search />
      },
      {
        path: "/cards",
        element: <CardSearch />
      },
      {
        path: "/register",
        element: <Register />
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/dashboard",
        element: (
        <ProtectedRoute requiredRole="admin">
          <Dashboard />
        </ProtectedRoute>),
        children: [
          {
            path: "/dashboard/",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashReports />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/cards",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashboardCards />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashImages />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/graded/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWallGrader />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWall />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/game/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWallGame />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/language/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWallLanguage />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/set/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWallSet />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/set-year/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWallSetYear />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/card-wall/owner/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <CardWallOwner />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/cards/new",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashNewCards />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/reports/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <YearlyReports />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/financials",
            element: (
            <ProtectedRoute requiredRole="admin">
              <Financials />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/search",
            element: (
            <ProtectedRoute requiredRole="admin">
              <SearchWall />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/sold",
            element: (
            <ProtectedRoute requiredRole="admin">
              <SoldReport />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/submissions",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashSubmissions />
            </ProtectedRoute>),
          },
          {
          path: "/dashboard/submissions/add",
          element: (
          <ProtectedRoute requiredRole="admin">
            <DashSubAdd />
          </ProtectedRoute>),
          },
          {
            path: "/dashboard/submissions/:id/edit",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashSubEdit />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/submissions/:id",
            element: (
            <ProtectedRoute requiredRole="admin">
              <DashSubmission />
            </ProtectedRoute>),
          },
          {
            path: "/dashboard/add/",
            element: (<ProtectedRoute requiredRole="admin"><CardAdd /></ProtectedRoute>),
          },
        ]
      },
    ]
  },
]);

function App() {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
