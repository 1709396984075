import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./cardSearch.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoverDisplay from '../components/CardSearchImage';
import Pagination from 'react-bootstrap/Pagination';  // Import Pagination component from react-bootstrap

function DB() {
  const [cardList, setCardList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const query =""

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`https://api.alientrader.cards/data/dbsearch`, {
          params: { q: query },
        });
        setCardList(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);

  // handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // calculate items for current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cardList.slice(indexOfFirstItem, indexOfLastItem);

  // calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(cardList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="app">
      <Pagination>
        {pageNumbers.map(number => (
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
      <Container>
        <Row className='justify-content-center'>
          {currentItems.map((item, index) => (
            <Col sm={4} className='custom-card-box shadow-sm' key={item.card_id}>
              <Row>
                <div className='custom-info-header'><Link to={`/card/${item.card_id}`}>{item.card_pokemon}</Link></div>
              </Row>
              <Row className='card-info'>
                <Col className="col-right-light">{item.card_id}</Col>
              </Row>
              <Row className='card-images'>
                <CoverDisplay key={index} image={item} />
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card_pokemon}</Col>
                <Col className="col-right">{item.card_set_year}</Col>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card}</Col>
                <Col className="col-right">{item.card_language}</Col>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card_owner}</Col>
                <Col className="col-right">{item.card_set}</Col>
              </Row>
              <Row className='card-info'>
                <Col className="col-left">{item.card_grade_number} {item.card_grade}</Col>
                <Col className="col-center">{item.card_grader}</Col>
                <Col className="col-right">{item.card_grade_cert}</Col>
              </Row>
              <br/>
            </Col>
          ))}
        </Row>
      </Container>
      <Pagination>
        {pageNumbers.map(number => (
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
}

export default DB;