import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthProvider'
import './Navbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../assets/Alien-Blank.png";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';


function NavbarUser() {
  const { user } = useContext(AuthContext); // Access user data from AuthContext
  return (
    <Navbar fixed="top" expand="xl" bg="black" variant='dark' className='custom-navbar shadow'>
      <Container fluid="xl">
        <Navbar.Brand href="/"><img
              alt=""
              src={Logo}
              width="45"
              height="45"
              className="d-inline-block align-center"
         />{' '}Alien Trader Co</Navbar.Brand>
        <Navbar.Text className="custom-auth justify-content-end">
        {user ? ( // If user exists, show username and LogoutIcon
            <>
              {user && user.role === 'admin' && <Nav.Link href="/dashboard/add"><AddCircleIcon /></Nav.Link>}
              {user && user.role === 'admin' && <Nav.Link href="/dashboard"><DeveloperBoardIcon /></Nav.Link>}
              <Nav.Link href="/db"><SearchRoundedIcon /></Nav.Link>
              <Nav.Link href="/dashboard/card-wall"><WallpaperIcon /></Nav.Link>
            </>
          ) : ( // If user does not exist, show NoAccountsIcon and Login link
            <>
              <Nav.Link href="/login"><NoAccountsIcon /> Login</Nav.Link>
            </>
          )}
        </Navbar.Text>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {user && user.role === 'admin' && 
              <>
                <Nav.Link href="/db"><SearchRoundedIcon /> Search</Nav.Link>
                <Nav.Link href="/dashboard/cards/new"><YoutubeSearchedForIcon /> Search Newest</Nav.Link>
                <Nav.Link href="/cards">Cards</Nav.Link>
                <Nav.Link href="/logout"><LogoutIcon /> Logout</Nav.Link>
              </>
            }
            {/* {user && user.role === 'admin' && <NavDropdown title="Dashboard" id="basic-nav-dropdown">
              <NavDropdown.Item href="/dashboard/cards">Cards</NavDropdown.Item>
              <NavDropdown.Divider />
            </NavDropdown>} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarUser;
