import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import './submission.css'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';


function SubAdd() {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [subGrader, setSubGrader] = useState('')
    const [subOrderNumber, setSubOrderNumber] = useState('')
    const [subDate, setSubDate] = useState('')
    const [subReturnDate, setSubReturnDate] = useState('')
    const [subPreCost, setSubPreCost] = useState('')
    const [subUpcharge, setSubUpcharge] = useState('')
    const [subTotalCards, setSubTotalCards] = useState('')
    const [subShippingTo, setSubShippingTo] = useState('')
    const [subShippingFrom, setSubShippingFrom] = useState('')
    const [subNotes, setSubNotes] = useState('')

    const handleSave = async () => {
        try {
          const subData = {
            sub_grader: subGrader,
            sub_order_number: subOrderNumber,
            sub_date: subDate,
            sub_return_date: subReturnDate,
            sub_precost: subPreCost,
            sub_upcharge: subUpcharge,
            sub_total_cards: subTotalCards,
            sub_shipping_to: subShippingTo,
            sub_shipping_from: subShippingFrom,
            sub_notes: subNotes,
          };
    
          const response = await axios.post('https://api.alientrader.cards/submission/add/', subData);
    
          if (response.data.success) {
            alert('Submission saved successfully!');
            navigate(`/dashboard/submissions/`);
          } else {
            alert('Error saving submission.');
          }
        } catch (error) {
          console.error("There was an error saving the submission", error);
          alert('Error saving submission.');
        }
      };

  return (
    <div>
        <div className='actions'>
            <div className='subBack'><CancelIcon fontSize="large" onClick={goBack}/></div>
            <div className='sub'><h1>Add New Submission</h1></div>
            <div className='subEdit'><button className='edit-button' onClick={handleSave}><SaveIcon fontSize="large" /></button></div>
        </div>
        <Container>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col sm={8} className='subAddBox shadow-sm'>
                    <Row><div className='subAddHeader'>Submission Info</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Grader:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_grader"
                                    className='edit-input'
                                    value={subGrader}
                                    onChange={(e) => setSubGrader(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Order Number:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_order_number"
                                    className='edit-input'
                                    value={subOrderNumber}
                                    onChange={(e) => setSubOrderNumber(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Submission Date:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="sub_date"
                                    className='edit-input'
                                    onChange={(e) => setSubDate(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Return Date:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="sub_return_date"
                                    className='edit-input'
                                    onChange={(e) => setSubReturnDate(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Total Cards:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_total_cards"
                                    className='edit-input'
                                    value={subTotalCards}
                                    onChange={(e) => setSubTotalCards(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Precost:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_precost"
                                    className='edit-input'
                                    value={subPreCost}
                                    onChange={(e) => setSubPreCost(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Upcharge:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_upcharge"
                                    className='edit-input'
                                    value={subUpcharge}
                                    onChange={(e) => setSubUpcharge(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Ship To:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_shipping_to"
                                    className='edit-input'
                                    value={subShippingTo}
                                    onChange={(e) => setSubShippingTo(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Ship From:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_shipping_from"
                                    className='edit-input'
                                    value={subShippingFrom}
                                    onChange={(e) => setSubShippingFrom(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Notes:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <textarea
                                    type="textarea"
                                    id="sub_notes"
                                    className='edit-input'
                                    onChange={(e) => setSubNotes(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row><br /></Row>
                </Col>
            </Row>
        </Container>
        <div className='actions'>
            <div className='subBack'><CancelIcon fontSize="large" onClick={goBack}/></div>
            <div className='sub'><h1>-</h1></div>
            <div className='subEdit'><button className='edit-button' onClick={handleSave}><SaveIcon fontSize="large" /></button></div>
        </div>
    </div>
  )
}

export default SubAdd