import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardWall from '../components/cardWalls'
import SoldInfo from '../components/soldInfo'
import TotalInfo from '../components/totalInfo'
import Submission from '../../submission/submissionsDash.tsx';
import Sold from'../sold/soldTable.tsx'
import Pokemon from '../components/pokemon.js'
import Newest from '../../card/walls/newest'

function reports() {
  return (
    <div className='reports'>
        <Container fluid='xl'>
          <Row className='d-flex justify-content-center'>
            <CardWall />
            <SoldInfo />
            <TotalInfo />
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md="10" className='info-box shadow-sm'>
            <Row><div className='info-header'>Newest Cards</div></Row>
              <Newest />
              <Pokemon />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xl={4} md={5}>
              <Submission />
            </Col>
            <Col xl={8} lg={7} md={7}>
              <Sold />
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default reports