import '../card.css'
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import Axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoverDisplay from '../../components/imagesPageSmall';
import '../../dashboard/components/styles.css'

function GraderImages() {
  const [cardList, setCardList] = useState([]);
  const {id} = useParams()

  useEffect(()=> {
    Axios.get(`https://api.alientrader.cards/card-wall/newest/`)
    .then((response)=> {
      setCardList(response.data);
    })
    .catch(error => {
      console.error('Error fetching card data:', error);
    });
  }, [id])

  return (
            <Row className='DashCardWallNewestPadding'>
              {cardList.map((item, index) => (
                <Col className='imagesPageRow d-flex align-items-center justify-content-center' key={item.card_id}>
                  <CoverDisplay key={index} image={item}/>
                </Col>
              ))}
            </Row>
  );
}

export default GraderImages;